import {
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import satori from 'satori'
import { formatDate } from '@/utils/date'
import {
  toHexColor,
  hue2rgb,
  rgb2Hsl,
  loadImage,
  cvtDataURL2File,
  getImageData,
} from '@/utils/objectUtil'

type MembershipCardSVGInput = {
  width: number
  height: number
  primaryColor?: string
  title?: string
  issuerName?: string
  logoImage?: string
  startDate?: string
  endDate?: string
}
type MembershipCardSVGProps = {
  (props: MembershipCardSVGInput): Promise<File | undefined>
}

const generateColorShema = (color: string) => {
  const colorHSL = rgb2Hsl(color)
  // let dominantColor: string
  let textColor: string
  let textColorVariant: string

  if (colorHSL.lightness > 0.5) {
    textColor = toHexColor(hue2rgb(colorHSL.hue * 360, colorHSL.saturation * 100, 10))
    textColorVariant = toHexColor(hue2rgb(colorHSL.hue * 360, colorHSL.saturation * 100, 40))
  } else {
    textColor = toHexColor(hue2rgb(colorHSL.hue * 360, colorHSL.saturation * 100, 90))
    textColorVariant = toHexColor(hue2rgb(colorHSL.hue * 360, colorHSL.saturation * 100, 60))
  }

  return {
    dominantColor: color,
    textColor,
    textColorVariant,
  }
}

export const GenerateCredentialImage: MembershipCardSVGProps = async ({
  ...props
}: MembershipCardSVGInput) => {
  const notoSansJP = await fetch('/fonts/NotoSansJP-SemiBold.ttf')
    .then((resp) => resp.arrayBuffer())
    .catch((err) => console.error(err))

  const { dominantColor, textColor, textColorVariant } = generateColorShema(
    props.primaryColor || '#444444',
  )

  console.log('start SVG generation')

  // avoid CORS error on satori
  const iconImage = await getImageData(`${props.logoImage}?rd=${new Date().getTime().toString()}`)

  let membershipCardSvg = await satori(
    <div
      style={{
        width: '280px',
        height: '176px',
        padding: '2px',
        borderRadius: '12px',
        background: 'linear-gradient(150deg, #D2D2D2 0%, #666666 100%)',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: '0px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '172px',
          background: dominantColor || '#444444',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '8px 20px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'center',
            gap: '6px',
          }}
        >
          <img
            src={iconImage || '/logos/logoMarkColored.png'}
            alt='logo'
            width='32px'
            height='32px'
            style={{ objectFit: 'contain' }}
            crossOrigin='anonymous'
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '2px',
            }}
          >
            <h3
              style={{
                fontSize: '18px',
                lineHeight: '100%',
                fontWeight: '600',
                color: textColor,
                margin: '0px',
              }}
            >
              {props.title || 'タイトル'}
            </h3>
            <h4
              style={{
                fontSize: '12px',
                lineHeight: '100%',
                fontWeight: '600',
                color: textColorVariant,
                margin: '0px',
                transform: 'translate(2px, 0px)',
              }}
            >
              {props.issuerName || 'Loading...'}
            </h4>
          </div>
        </div>
        <img
          src={iconImage || '/logos/logoMarkColored.png'}
          alt='logo'
          width='100px'
          height='100px'
          style={{
            position: 'absolute',
            bottom: '-12px',
            right: '-12px',
            opacity: '0.3',
            objectFit: 'contain',
          }}
          crossOrigin='anonymous'
        />
      </div>
    </div>,
    {
      width: 280,
      height: 176,
      fonts: [
        {
          name: 'Noto Sans JP',
          data: notoSansJP as ArrayBuffer,
          weight: 600,
          style: 'normal',
        },
      ],
    },
  )
  membershipCardSvg = membershipCardSvg
    .replace(/width="[^"]*"/, `width="${props.width}"`)
    .replace(/height="[^"]*"/, `height="${props.height}"`)
  let canvas = document.createElement('canvas')
  canvas.width = props.width
  canvas.height = props.height

  let ctx = canvas.getContext('2d')
  if (!ctx) return

  const img = await loadImage(
    'data:image/svg+xml;charset=utf-8;base64,' +
      btoa(unescape(encodeURIComponent(membershipCardSvg))),
  )
  ctx?.drawImage(img, 0, 0)
  return await cvtDataURL2File(canvas.toDataURL('image/png', 2), `${props.title}.png`)
}

type Props<T extends FieldValues> = {
  width: number
  height: number
  wName: Path<UnPackAsyncDefaultValues<T>>
  wPrimaryColor: Path<UnPackAsyncDefaultValues<T>>
  issuerName: string
  wStartDate: Path<UnPackAsyncDefaultValues<T>>
  wEndDate: Path<UnPackAsyncDefaultValues<T>>
  icon?: string
}

export const MembershipCard = <T extends FieldValues>({
  width,
  height,
  wName,
  wPrimaryColor,
  issuerName,
  icon,
  wStartDate,
  wEndDate,
}: Props<T>) => {
  const { control } = useFormContext<T>()

  const workspaceName = useWatch({ control, name: wName })
  const primaryColor = useWatch({ control, name: wPrimaryColor })
  // const startDate = useWatch({ control, name: wStartDate })
  // const endDate = useWatch({ control, name: wEndDate })

  const today = new Date().toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const { dominantColor, textColor, textColorVariant } = generateColorShema(
    primaryColor || '#444444',
  )

  return (
    <div
      style={{
        width: '280px',
        height: '176px',
        padding: '2px',
        borderRadius: '12px',
        background: 'linear-gradient(150deg, #D2D2D2 0%, #666666 100%)',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: '0px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '172px',
          background: dominantColor || '#444444',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '8px 20px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'center',
            gap: '6px',
          }}
        >
          <img
            src={icon || '/logos/logoMarkColored.png'}
            alt='logo'
            width='32px'
            height='32px'
            style={{ objectFit: 'contain' }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '2px',
            }}
          >
            <h3
              style={{
                fontSize: '18px',
                lineHeight: '100%',
                fontWeight: '600',
                color: textColor,
                margin: '0px',
              }}
            >
              {workspaceName || 'タイトル'}
            </h3>
            <h4
              style={{
                fontSize: '12px',
                lineHeight: '100%',
                fontWeight: '600',
                color: textColorVariant,
                margin: '0px',
                transform: 'translate(2px, 0px)',
              }}
            >
              {issuerName || 'Loading...'}
            </h4>
          </div>
        </div>
        {/* <div style={{ margin: '0px', display: 'flex', flex: 'none' }}>
          <p
            style={{
              margin: '0px',
              fontSize: '12px',
              lineHeight: '100%',
              color: textColorVariant,
            }}
          >
            {startDate ? formatDate(startDate.toISOString()) : today} —{' '}
            {endDate ? formatDate(endDate.toISOString()) : '現在'}
          </p>
        </div> */}
        <img
          src={icon || '/logos/logoMarkColored.png'}
          alt='logo'
          width='100px'
          height='100px'
          style={{
            position: 'absolute',
            bottom: '-12px',
            right: '-12px',
            opacity: '0.3',
            objectFit: 'contain',
          }}
        />
      </div>
    </div>
  )
}
