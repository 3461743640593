export const Calendar = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.9 2.30005C6.595 2.30005 6.3025 2.42121 6.08683 2.63688C5.87116 2.85254 5.75 3.14505 5.75 3.45005V4.60005H4.6C3.99001 4.60005 3.40499 4.84237 2.97366 5.2737C2.54232 5.70504 2.3 6.29005 2.3 6.90005V18.4C2.3 19.01 2.54232 19.5951 2.97366 20.0264C3.40499 20.4577 3.99001 20.7 4.6 20.7H18.4C19.01 20.7 19.595 20.4577 20.0263 20.0264C20.4577 19.5951 20.7 19.01 20.7 18.4V6.90005C20.7 6.29005 20.4577 5.70504 20.0263 5.2737C19.595 4.84237 19.01 4.60005 18.4 4.60005H17.25V3.45005C17.25 3.14505 17.1288 2.85254 16.9132 2.63688C16.6975 2.42121 16.405 2.30005 16.1 2.30005C15.795 2.30005 15.5025 2.42121 15.2868 2.63688C15.0712 2.85254 14.95 3.14505 14.95 3.45005V4.60005H8.05V3.45005C8.05 3.14505 7.92884 2.85254 7.71318 2.63688C7.49751 2.42121 7.205 2.30005 6.9 2.30005V2.30005ZM6.9 8.05005C6.595 8.05005 6.3025 8.17121 6.08683 8.38688C5.87116 8.60254 5.75 8.89505 5.75 9.20005C5.75 9.50505 5.87116 9.79755 6.08683 10.0132C6.3025 10.2289 6.595 10.35 6.9 10.35H16.1C16.405 10.35 16.6975 10.2289 16.9132 10.0132C17.1288 9.79755 17.25 9.50505 17.25 9.20005C17.25 8.89505 17.1288 8.60254 16.9132 8.38688C16.6975 8.17121 16.405 8.05005 16.1 8.05005H6.9Z'
      fill='currentColor'
    />
  </svg>
)
