import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import { useVESSTheme } from '@/hooks/useVESSTheme'

type Props = {
  text?: string
}
const LottieBaseLoading = dynamic(() => import('@/components/atom/Animations/LottieBaseLoading'), {
  ssr: false,
})
export default function LoadingModal(props: Props) {
  const { currentTheme, currentTypo, getBasicFont } = useVESSTheme()
  const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
  `
  const Content = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `
  const Loading = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0px, 30%, 0px);
  `
  const LoadingText = styled.p`
    color: ${currentTheme.onSurface};
    text-align: left;
    ${getBasicFont(currentTypo.bodyBold.large)};
  `
  return (
    <Container>
      <Content>
        <Loading>
          <LottieBaseLoading loop play style={{ width: 200, height: 200 }} />
        </Loading>
        <LoadingText>{props.text || ''}</LoadingText>
      </Content>
    </Container>
  )
}
