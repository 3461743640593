import styled from '@emotion/styled'
import { FinalColor } from 'extract-colors/lib/types/Color'
import { FC, useRef } from 'react'
import type { AriaRadioProps } from 'react-aria'
import { usePress, VisuallyHidden, useHover, useRadio, mergeProps } from 'react-aria'
import { UseFormRegisterReturn } from 'react-hook-form'
import type { RadioGroupState } from 'react-stately'
import { SemanticColors } from '@/@types/theme'
import { useRadioGroupContext } from '@/hooks/useRadioGroup'
import { useVESSTheme } from '@/hooks/useVESSTheme'

type ColorPickItemProps = {
  color?: FinalColor
  onPress?: (color: FinalColor) => void
  isBorder?: boolean
  width?: string
  height?: string
  register?: UseFormRegisterReturn
} & AriaRadioProps

export const ColorPickItem: FC<ColorPickItemProps> = ({
  color,
  onPress,
  isBorder = false,
  width = '40px',
  height = '40px',
  register,
  ...props
}) => {
  const { currentTheme } = useVESSTheme()
  const radioRef = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLLabelElement>(null)
  const { hoverProps, isHovered } = useHover({ isDisabled: false })
  const { isPressed, pressProps } = usePress({ ref: labelRef })
  const { radioGroupState } = useRadioGroupContext()
  const { inputProps, isSelected } = useRadio(
    { 'aria-label': '', ...props },
    radioGroupState as RadioGroupState,
    radioRef,
  )

  const { width: _w, height: _h } = inputProps

  return (
    <ColorItemFrame
      colorTheme={currentTheme}
      width={width}
      height={height}
      color={color?.hex || '#333333'}
      data-is-border={isBorder}
      data-hovered={isHovered}
      data-selected={isSelected}
      data-pressed={isPressed}
      {...mergeProps(pressProps, hoverProps)}
      ref={labelRef}
    >
      <VisuallyHidden>
        <input {...mergeProps(inputProps)} ref={radioRef} {...register} />
      </VisuallyHidden>
    </ColorItemFrame>
  )
}

const ColorItemFrame = styled.label<{
  colorTheme: SemanticColors
  width: string
  height: string
  color: string
  isSelected?: boolean
}>`
  display: block;
  width: ${(props) => props.width};
  max-width: 48px;
  height: ${(props) => props.height};
  border-radius: 12px;
  background: ${(props) => props.color};
  transition: all 0.5s cubic-bezier(0, 0.7, 0.3, 1);
  cursor: pointer;

  &[data-is-border='true'] {
    border: 1px solid ${(props) => props.colorTheme.outline};
  }

  &[data-hovered='true'] {
    transform: scale(1.05);
  }

  &[data-pressed='true'] {
    transform: scale(0.95);
  }
  &[data-selected='true'] {
    outline: 2px solid ${(props) => props.colorTheme.outline};
    outline-offset: 2px;
  }
`
