import { atom, useAtom, useSetAtom, useAtomValue } from 'jotai'
import { KmsOrganizationType } from '@/@types/kms'
import { TagOption } from '@/constants/tags'

export type connectionStatusType = 'connecting' | 'connected' | 'disconnected'

export const connectionStatus = atom<connectionStatusType>('disconnected')

export const useStateConnectionStatus = () => useAtom(connectionStatus)
export const useSetStateConnectionStatus = () => useSetAtom(connectionStatus)
export const useConnectionStatus = () => useAtomValue(connectionStatus)

export const myDid = atom<string | undefined>(undefined)

export const useStateMyDid = () => useAtom(myDid)
export const useSetStateMyDid = () => useSetAtom(myDid)
export const useMyDid = () => useAtomValue(myDid)

export const account = atom<string | undefined>(undefined)

export const useStateAccount = () => useAtom(account)
export const useSetStateAccount = () => useSetAtom(account)
export const useAccount = () => useAtomValue(account)

export const originalAddress = atom<string | undefined>(undefined)

export const useStateOriginalAddress = () => useAtom(originalAddress)
export const useSetStateOriginalAddress = () => useSetAtom(originalAddress)
export const useOriginalAddress = () => useAtomValue(originalAddress)

export const chainId = atom<number | undefined>(undefined)

export const useStateChainId = () => useAtom(chainId)
export const useSetStateChainId = () => useSetAtom(chainId)
export const useChainId = () => useAtomValue(chainId)

export const kmsOrgs = atom<KmsOrganizationType[]>([])

export const useStateKmsOrgs = () => useAtom(kmsOrgs)
export const useSetStateKmsOrgs = () => useSetAtom(kmsOrgs)
export const useKmsOrgs = () => useAtomValue(kmsOrgs)

export const selectedChips = atom<TagOption[]>([])
export const useStateSelectedChips = () => useAtom(selectedChips)
