export const MoonIcon = () => (
  <svg viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M29.7501 18.1192C29.5273 20.5306 28.6222 22.8287 27.141 24.7446C25.6597 26.6605 23.6634 28.1149 21.3857 28.9376C19.108 29.7603 16.6431 29.9173 14.2794 29.3903C11.9157 28.8633 9.75101 27.6739 8.03859 25.9615C6.32617 24.2491 5.13685 22.0844 4.60981 19.7207C4.08276 17.357 4.23979 14.8921 5.06251 12.6144C5.88523 10.3367 7.33961 8.34044 9.25549 6.85915C11.1714 5.37786 13.4695 4.47285 15.8809 4.25C14.4691 6.16005 13.7897 8.51339 13.9664 10.882C14.143 13.2506 15.1639 15.4772 16.8434 17.1567C18.5229 18.8362 20.7495 19.8571 23.1181 20.0337C25.4867 20.2104 27.8401 19.531 29.7501 18.1192Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
