import styled from '@emotion/styled'
import { FC } from 'react'
import type { AriaRadioGroupProps } from 'react-aria'
import { useRadioGroup } from 'react-aria'
import { useRadioGroupState } from 'react-stately'
import { RadioGroupProvider } from '@/hooks/useRadioGroup'

type RadioGroupProps = AriaRadioGroupProps & {
  children: React.ReactNode
  width?: string
}

export const RadioGroup: FC<RadioGroupProps> = ({ children, width = '100%', ...props }) => {
  const radioGroupState = useRadioGroupState(props)
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(
    props,
    radioGroupState,
  )

  return (
    <RadioGroupFrame width={width} {...radioGroupProps}>
      <RadioGroupProvider state={radioGroupState}>{children}</RadioGroupProvider>
    </RadioGroupFrame>
  )
}

const RadioGroupFrame = styled.div<{ width: string }>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: ${(props) => props.width};
  gap: 12px;
`
