import { FinalColor } from 'extract-colors/lib/types/Color'
import { ICONS, IconsType } from '@/components/atom/Icons/Icon'

const ALPHA_USERS = [
  'did:pkh:eip155:1:0x975d776e90dedd27a6e0afc43901b164a9ee8ad0',
  // 'did:pkh:eip155:1:0x92fc3534560b798136bb47e833a0b0152e60c208',
  'did:pkh:eip155:1:0xf6dcc520f11ad600da7e01da44e0e70d094ea246',
  'did:pkh:eip155:1:0x92fc3534560b798136bb47e833a0b0152e60c208',
  'did:pkh:eip155:1:0xbe2896057d7abfe53da4bbc3fce3e0f2f715232b',
  'did:pkh:eip155:1:0xd35c49e9d43aa08cc6f7fe12e9c56a7f9a2369a0',
  'did:pkh:eip155:1:0xadf14e228bbcc1b23aaca6c60301fe38177745f1',
]

export const isAlphaUsers = (did: string) => {
  return ALPHA_USERS.some((u) => u.toLowerCase() === did.toLowerCase())
}

export const DEFAULT_LOADING_MESSAGE = 'It may take a while. Please wait.'

export const NAVIGATION_ITEM = {
  MEMBERSHIP: 'Membership',
  ATTENDANCE: 'Attendance',
  PROFILE: 'Home',
  JOBS: 'Jobs',
  SETTING: 'Setting',
  CERTIFICATION: 'Certification',
  CREDENTIAL: 'Credential',
} as const

export type NavigationItemType = typeof NAVIGATION_ITEM[keyof typeof NAVIGATION_ITEM]

export type NAVIGATION_LIST_TYPE = {
  item: NavigationItemType
  icon: string
  path: string
}

export const NAVIGATION_LIST_V2: NAVIGATION_LIST_TYPE[] = [
  // { item: NAVIGATION_ITEM.PROFILE, icon: 'home', path: '/' },
  { item: NAVIGATION_ITEM.CREDENTIAL, icon: 'credential', path: '/' },
  { item: NAVIGATION_ITEM.SETTING, icon: 'setting', path: '/setting' },
]

export const getNaviItem = (path: string): NAVIGATION_LIST_TYPE => {
  if (path === '/')
    return {
      item: NAVIGATION_ITEM.PROFILE,
      icon: ICONS.VOXEL,
      path: '/',
    }
  return (
    NAVIGATION_LIST_V2.find((n) => n.path !== '/' && path.startsWith(n.path)) || {
      item: NAVIGATION_ITEM.PROFILE,
      icon: ICONS.VOXEL,
      path: '/',
    }
  )
}

export const DefaultCardColor: FinalColor = {
  hex: '#242424',
  red: 36,
  green: 36,
  blue: 36,
  area: 1,
  hue: 0,
  saturation: 0,
  lightness: 0.14,
  intensity: 1,
}
