import styled from '@emotion/styled'
import { useModal, useModalContext } from 'kai-kit'
import React, { FC } from 'react'

type Props = {
  navigation?: React.ReactNode
  children?: React.ReactNode
  backgroundColor?: string
  width?: string
  height?: string
  showGrid?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const NCLayout: FC<Props> = ({
  navigation,
  children,
  width = '100%',
  height = '100%',
  showGrid = false,
  backgroundColor = 'transparent',
}) => {
  const { attachToRef } = useModal()
  const { isOpenSomeModal } = useModalContext()
  return (
    <LayoutContainer
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      data-modal-open={isOpenSomeModal}
      ref={attachToRef}
    >
      <NaviContainer showGrid={showGrid}>{navigation}</NaviContainer>
      <ContentContainer showGrid={showGrid}>{children}</ContentContainer>
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div<{ width: string; height: string; backgroundColor: string }>`
  display: grid;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  grid-template-columns: 280px 1fr;
  background: ${(props) => props.backgroundColor};
  transition: all var(--kai-motion-sys-duration-fast) var(--kai-motion-sys-easing-standard);
  transition-property: transform opacity;

  &[data-modal-open='true'] {
    transform: scale(0.98);
    opacity: 0.4;
  }
`

const NaviContainer = styled.nav<{ showGrid: boolean }>`
  grid-column: 1/2;
  grid-row: 1/2;
  width: 280px;
  height: 100vh;
  position: fixed;
  background: ${(props) => (props.showGrid ? 'aliceblue' : 'transparent')};
`

const ContentContainer = styled.main<{ showGrid: boolean }>`
  grid-column: 2/3;
  grid-row: 1/2;
  background: ${(props) => (props.showGrid ? 'lavender' : 'transparent')};
`
