import { format, isBefore, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const parseISOStrToDate = (iso: string): Date => {
  return zonedTimeToUtc(iso, 'utc')
}

export const formatDate = (date?: string): string => {
  if (!date) return ''
  return format(parseISO(date), 'yyyy-MM-dd')
}

export const convertTimestampToDateStr = (timestamp?: string): string => {
  if (!timestamp) return ''
  try {
    const d = new Date(Number(timestamp) * 1000)
    return formatDate(d.toISOString())
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const convertDateStrToTimestamp = (str?: string): number => {
  if (!str) return 0
  return new Date(str).getTime()
}

export const isExpired = (date?: string): boolean => {
  if (!date) return false
  const now = new Date()
  return isBefore(parseISOStrToDate(date), now)
}

export const IntervalAsDays = (start: Date, end: Date): number => {
  return Math.floor((end.getTime() - start.getTime()) / 24 / 60 / 60 / 1000)
}
