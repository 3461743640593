import { UserProvider as Auth0Provider } from '@auth0/nextjs-auth0/client'
import { ThemeProvider, Global, css } from '@emotion/react'
import { Noto_Sans_JP, Noto_Sans } from '@next/font/google'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { DehydratedState } from '@tanstack/react-query'
import { Provider as JotaiProvider } from 'jotai'
import { KaiProvider } from 'kai-kit'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { useState } from 'react'
import { VESSToast } from '@/components/atom/Toasts/VESSToast'
import { AuthLayout } from '@/components/layouts/AuthLayout'
import { BasicLayout } from '@/components/layouts/BasicLayout'
import { theme } from '@/lib/theme'
import 'modern-css-reset/dist/reset.min.css'
import '@/styles/globals.css'
import '@/tokens/build/css/kai-tokens.css'

const notoSans = Noto_Sans({
  style: 'normal',
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
})
const notoSansJP = Noto_Sans_JP({
  style: 'normal',
  weight: ['400', '500', '700'],
  subsets: ['latin'],
})

const global = css`
  :root {
    --kai-font-family-base: ${notoSans.style.fontFamily};
  }
  html {
    font-family: ${notoSans.style.fontFamily}, ${notoSansJP.style.fontFamily};
  }
`

type AppPropsWithLayout = AppProps<{ dehydratedState: DehydratedState }> & {
  Component: NextPage
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  )
  const { dehydratedState, ...props } = pageProps

  return (
    <>
      <Global styles={global} />
      <JotaiProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <ThemeProvider theme={theme}>
              <Auth0Provider>
                <AuthLayout>
                  <KaiProvider>
                    <BasicLayout>
                      <Component {...props} />
                    </BasicLayout>
                  </KaiProvider>
                  <VESSToast />
                </AuthLayout>
              </Auth0Provider>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </JotaiProvider>
    </>
  )
}
export default appWithTranslation(App)
