import { useUser } from '@auth0/nextjs-auth0/client'
import { FC, useEffect } from 'react'
import { useDID } from '@/hooks/useDID'
import { useDIDAccount } from '@/hooks/useDIDAccount'
import { getVESS } from '@/lib/vess'
type Props = {
  children: React.ReactNode
}
export const AuthLayout: FC<Props> = ({ children }) => {
  const { connectDID } = useDID()
  const { did } = useDIDAccount()
  const vess = getVESS()
  const { user } = useUser()

  useEffect(() => {
    async function autoConnect() {
      await connectDID()
    }
    if (user && (!vess.isAuthenticated() || !did)) {
      autoConnect()
    }
  }, [did, user])

  return <>{children}</>
}
