import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import type { Organization } from 'vess-kit-core'
import { removeCeramicPrefix } from 'vess-kit-web'
import { useToast } from './useToast'
import { useVESSLoading } from './useVESSLoading'
import { useDIDAccount } from '@/hooks/useDIDAccount'
import { useSetStateKmsOrgs } from '@/jotai/account'
import { useStateCurrentWorkspaceId } from '@/jotai/workspace'
import { getKmsOrganizations } from '@/lib/kms'
import { getVESS } from '@/lib/vess'
import { removeUndefinedFromArray } from '@/utils/objectUtil'

export type OrganizationWithBackendId = Organization & {
  orgId?: string
  useCompose?: boolean
  address?: string
}

export const useWorkspace = () => {
  const { did } = useDIDAccount()
  const [currentWorkspaceId, setCurrentWorkspaceId] = useStateCurrentWorkspaceId()
  const setStateKmsOrgs = useSetStateKmsOrgs()

  const { data: workspaces, isInitialLoading: isLoadingOrg } = useQuery<
    OrganizationWithBackendId[]
  >(['kmsOrganizations'], () => fetchOrganizationFromKMS(), {
    enabled: !!did && did !== '',
    staleTime: Infinity,
    cacheTime: 300000,
    retry: false,
  })

  const fetchOrganizationFromKMS = async () => {
    try {
      const orgs = await getKmsOrganizations()
      console.log({ orgs })
      setStateKmsOrgs(orgs)
      try {
        const promises: Promise<OrganizationWithBackendId | undefined>[] = []
        for (const org of orgs) {
          //TODO: fix error: it throws error if organization is saved on old ceramic node.
          promises.push(getOrganization(org.id, org.ceramicId, org.useCompose, org.address))
        }
        const result = await Promise.all(promises)
        return removeUndefinedFromArray(result)
      } catch (error) {
        console.error({ error })
        // return org from backend if failed to get org from ceramic
        return orgs.map((org) => {
          return {
            name: org.name,
            icon: org.icon,
            desc: org.desc,
            primaryColor: org.primaryColor,
            orgId: org.id,
            id: org.id,
            useCompose: org.useCompose,
            address: org.address,
          } as OrganizationWithBackendId
        })
      }
    } catch (error) {
      console.error({ error })
      return []
    }
  }

  const getOrganization = async (
    orgId?: string,
    orgCeramicId?: string,
    useCompose?: boolean,
    address?: string,
  ) => {
    if (!orgId || !orgCeramicId) return
    const vessKit = getVESS()
    const org = await vessKit.getOrganization(removeCeramicPrefix(orgCeramicId))
    return { ...org, orgId, useCompose, address } as OrganizationWithBackendId
  }

  const workspace = useMemo(() => {
    if (!workspaces || workspaces.length === 0) return null
    if (currentWorkspaceId) {
      return workspaces?.find((ws) => ws.id === currentWorkspaceId) || workspaces[0]
    }
    setCurrentWorkspaceId(workspaces[0].id)
    return workspaces[0]
  }, [workspaces, currentWorkspaceId])

  return {
    isLoadingOrg,
    workspace,
    workspaces,
    setCurrentWorkspaceId,
  }
}
