export const CrossFilledIcon = () => (
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2581_846)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 31.0588C24.3167 31.0588 31.0588 24.3167 31.0588 16C31.0588 7.68323 24.3167 0.941162 16 0.941162C7.68323 0.941162 0.941162 7.68323 0.941162 16C0.941162 24.3167 7.68323 31.0588 16 31.0588ZM11.3512 9.35466C10.7999 8.80333 9.90599 8.80333 9.35466 9.35466C8.80333 9.90599 8.80333 10.7999 9.35466 11.3512L14.0034 16L9.35466 20.6488C8.80333 21.2001 8.80333 22.094 9.35466 22.6453C9.90599 23.1966 10.7999 23.1966 11.3512 22.6453L16 17.9965L20.6488 22.6453C21.2001 23.1966 22.094 23.1966 22.6453 22.6453C23.1966 22.094 23.1966 21.2001 22.6453 20.6488L17.9965 16L22.6453 11.3512C23.1966 10.7999 23.1966 9.90599 22.6453 9.35466C22.094 8.80333 21.2001 8.80333 20.6488 9.35466L16 14.0034L11.3512 9.35466Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2581_846'>
        <rect fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
)
