export const ENSIcon = () => (
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='16' fill='#CFE5FF' />
    <g clipPath='url(#clip0_2605_2688)'>
      <path
        d='M9.30267 14.1088C9.50075 14.529 9.99593 15.3696 9.99593 15.3696L15.6658 6L10.1445 9.85661C9.82262 10.0791 9.55027 10.3758 9.35219 10.7219C8.83225 11.7849 8.83225 13.021 9.30267 14.1088Z'
        fill='url(#paint0_linear_2605_2688)'
      />
      <path
        d='M7.07429 17.1744C7.19808 18.9791 8.11418 20.6601 9.55022 21.7479L15.6658 26.0001C15.6658 26.0001 11.8281 20.4871 8.60936 14.9988C8.28749 14.4302 8.06466 13.7875 7.96562 13.12C7.9161 12.8233 7.9161 12.5266 7.96562 12.23C7.89134 12.3783 7.71803 12.6997 7.71803 12.6997C7.39616 13.3672 7.17332 14.0841 7.04953 14.8258C6.97525 15.6169 6.97525 16.408 7.07429 17.1744Z'
        fill='#A0A8D4'
      />
      <path
        d='M22.6727 17.9158C22.4746 17.4956 21.9794 16.655 21.9794 16.655L16.3096 25.9999L21.8557 22.168C22.1775 21.9455 22.4499 21.6488 22.6479 21.3027C23.1431 20.2397 23.1679 19.0036 22.6727 17.9158Z'
        fill='url(#paint1_linear_2605_2688)'
      />
      <path
        d='M24.9257 14.8257C24.8019 13.021 23.8858 11.3399 22.4498 10.2522L16.3342 6C16.3342 6 20.1719 11.513 23.3906 17.0012C23.7125 17.5698 23.9353 18.2126 24.0344 18.8801C24.0839 19.1768 24.0839 19.4734 24.0344 19.7701C24.1086 19.6218 24.282 19.3004 24.282 19.3004C24.6038 18.6329 24.8267 17.9159 24.9505 17.199C25 16.3832 25 15.6168 24.9257 14.8257Z'
        fill='#A0A8D4'
      />
      <path
        d='M9.3519 10.7219C9.54997 10.3758 9.79756 10.0791 10.1442 9.85661L15.6655 6L9.99564 15.3449C9.99564 15.3449 9.50045 14.5043 9.30238 14.0841C8.83195 13.021 8.83195 11.7849 9.3519 10.7219ZM7.07404 17.1743C7.19784 18.979 8.11393 20.6601 9.54997 21.7478L15.6655 26C15.6655 26 11.8278 20.487 8.60912 14.9988C8.28725 14.4302 8.06441 13.7874 7.96538 13.1199C7.91586 12.8232 7.91586 12.5266 7.96538 12.2299C7.8911 12.3782 7.71778 12.6996 7.71778 12.6996C7.39591 13.3671 7.17308 14.0841 7.04928 14.8257C6.97501 15.6168 6.97501 16.4079 7.07404 17.1743ZM22.6724 17.9159C22.4743 17.4957 21.9791 16.6551 21.9791 16.6551L16.3093 26L21.8553 22.1681C22.1772 21.9456 22.4496 21.6489 22.6476 21.3028C23.1428 20.2398 23.1676 19.0037 22.6724 17.9159ZM24.9007 14.8504C24.7769 13.0457 23.8608 11.3646 22.4248 10.2769L16.334 6C16.334 6 20.1717 11.513 23.3904 17.0012C23.7123 17.5698 23.9351 18.2126 24.0342 18.8801C24.0837 19.1768 24.0837 19.4734 24.0342 19.7701C24.1084 19.6218 24.2817 19.3004 24.2817 19.3004C24.6036 18.6329 24.8265 17.9159 24.9502 17.199C24.9998 16.3832 24.9998 15.6168 24.9007 14.8504Z'
        fill='url(#paint2_linear_2605_2688)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_2605_2688'
        x1='15.9237'
        y1='6.23439'
        x2='8.66091'
        y2='14.1197'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.58' stopColor='#A0A8D4' />
        <stop offset='0.73' stopColor='#8791C7' />
        <stop offset='0.91' stopColor='#6470B4' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_2605_2688'
        x1='16.0765'
        y1='25.782'
        x2='23.3366'
        y2='17.9023'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.58' stopColor='#A0A8D4' />
        <stop offset='0.73' stopColor='#8791C7' />
        <stop offset='0.91' stopColor='#6470B4' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_2605_2688'
        x1='15.993'
        y1='5.90114'
        x2='15.993'
        y2='26.0742'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#513EFF' />
        <stop offset='0.18' stopColor='#5157FF' />
        <stop offset='0.57' stopColor='#5298FF' />
        <stop offset='1' stopColor='#52E5FF' />
      </linearGradient>
      <clipPath id='clip0_2605_2688'>
        <rect width='18' height='20' fill='white' transform='translate(7 6)' />
      </clipPath>
    </defs>
  </svg>
)
