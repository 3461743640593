import { useRouter } from 'next/router'
import { getAddressFromPkh, getAuthorizedSession } from 'vess-kit-web'
import { useVESSApi } from './useVESSApi'
import { isProd } from '@/constants/common'
import {
  useSetStateAccount,
  useSetStateChainId,
  useStateConnectionStatus,
  useSetStateMyDid,
  useSetStateOriginalAddress,
  useSetStateKmsOrgs,
} from '@/jotai/account'
import { getKmsOrganizations } from '@/lib/kms'
import { getVESS } from '@/lib/vess'
import { getCurrentDomain } from '@/utils/url'

export type orgInfo = {
  orgId: string
  address: string
}

const CHAIN_ID = 1
export const useDID = () => {
  const setMyDid = useSetStateMyDid()
  const setAccount = useSetStateAccount()
  const setOriginalAddress = useSetStateOriginalAddress()
  const setChainId = useSetStateChainId()
  const [connectionStatus, setConnectionStatus] = useStateConnectionStatus()
  const setStateKmsOrgs = useSetStateKmsOrgs()
  const router = useRouter()
  const vessKit = getVESS()
  const { getSessionFromKMS } = useVESSApi()

  // clear all state
  const clearState = (): void => {
    setMyDid(undefined)
    setAccount(undefined)
    setOriginalAddress(undefined)
    setChainId(undefined)
    setConnectionStatus('disconnected')
  }

  const connectDID = async (orgInfo?: orgInfo): Promise<void> => {
    if (connectionStatus === 'connecting') return
    setConnectionStatus('connecting')
    try {
      let info = orgInfo
      if (!orgInfo) {
        const orgs = await getKmsOrganizations()
        setStateKmsOrgs(orgs)
        info = orgs && orgs.length > 0 ? { orgId: orgs[0].id, address: orgs[0].address } : undefined
      }
      if (!info) {
        setConnectionStatus('disconnected')
        router.push('/kms/workspace')
        return
      }
      // connect vess sdk
      let sessionStr = ''
      if (!orgInfo) {
        const existedSession = await getAuthorizedSession()
        sessionStr = existedSession || (await getSessionFromKMS(info.orgId))
      } else {
        sessionStr = await getSessionFromKMS(info.orgId)
      }
      console.log({ info })
      const env = isProd() ? 'mainnet' : 'testnet-clay'
      const { session } = await vessKit.connectFromSession(sessionStr, info.address, env)
      console.log({ session })
      console.log('session.did.parent: ', session.did.parent)
      const address = getAddressFromPkh(session.did.parent)
      setMyDid(session.did.parent)
      setAccount(address)
      setOriginalAddress(address)
      setChainId(CHAIN_ID)
      setConnectionStatus('connected')
    } catch (error) {
      console.error(error)
      await disConnectDID()
      // await auth0Logout()
    }
  }

  const disConnectDID = async (): Promise<void> => {
    vessKit.disconnect()
    clearState()
  }

  const auth0Login = () => {
    if (typeof window === 'undefined') return
    let loginPath = `/api/auth/login/?rPath=${getCurrentDomain()}/kms/callback`
    router.push(loginPath)
  }

  const auth0Logout = async () => {
    await disConnectDID()
    router.push(`/api/auth/logout?returnTo=${getCurrentDomain()}/login`)
  }

  return {
    auth0Login,
    auth0Logout,
    connectDID,
  }
}
