import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useToast } from './useToast'
import { useVESSApi } from './useVESSApi'
import { useVESSLoading } from './useVESSLoading'
import { useVESSMember } from './useVESSMember'
import { useWorkspace } from './useWorkspace'
import { IssueCollectionRequest } from '@/@types/kms'
import { COLLECTION_CREATION_SUCCEED, COLLECTION_CREATION_FAILED } from '@/constants/toastMessage'
import { DEFAULT_LOADING_MESSAGE } from '@/constants/ui'
import type { GetCollectionItemResponse, GetCollectionResponse } from '@/lib/kms'
import { isGoodResponse } from '@/utils/http'

export const useCredentials = (collectionId?: string) => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()
  const { showLoading, closeLoading } = useVESSLoading()
  const { workspace } = useWorkspace()
  const { member } = useVESSMember(workspace?.orgId)
  const { fetchCollection, fetchDefaultCredItem, fetchAllCollection, addCollection } = useVESSApi()

  const { data: collection, isInitialLoading: isInitialLoadingCollecion } =
    useQuery<GetCollectionItemResponse | null>(
      ['collection', collectionId],
      () => fetchCollection(collectionId),
      {
        enabled: !!collectionId && collectionId !== '' && !!workspace?.orgId && !!member?.id,
        staleTime: Infinity,
        cacheTime: 300000,
      },
    )

  const { data: defaultCredItems, isInitialLoading } = useQuery<GetCollectionItemResponse | null>(
    ['defaultCredItems', workspace?.orgId],
    () => fetchDefaultCredItem(workspace?.orgId),
    {
      enabled: !!workspace?.orgId && workspace?.orgId !== '',
      staleTime: Infinity,
      cacheTime: 300000,
    },
  )

  const { data: originalCollections, isInitialLoading: isLoadingCollections } = useQuery<
    GetCollectionResponse[]
  >(['originalCollections', workspace?.orgId], () => fetchAllCollection(workspace?.orgId), {
    enabled: !!workspace?.orgId && workspace?.orgId !== '',
    staleTime: Infinity,
    cacheTime: 300000,
  })

  const collections = useMemo(() => {
    console.log({ originalCollections })
    if (!originalCollections) return []
    return originalCollections?.filter((col) => col.name !== 'default' && !col.parentId)
  }, [originalCollections])

  const defaultCollection = useMemo(() => {
    if (!originalCollections) return
    return originalCollections?.find((col) => col.name === 'default')
  }, [originalCollections])

  const { mutateAsync: createCollection } = useMutation<any, unknown, IssueCollectionRequest>(
    (param) => addCollection(param),
    {
      onMutate() {
        showLoading(DEFAULT_LOADING_MESSAGE)
      },
      onSuccess(res) {
        if (isGoodResponse(res.status)) {
          console.log({ res })
          closeLoading()
          showToast(COLLECTION_CREATION_SUCCEED)
        } else {
          closeLoading()
          showToast(`${COLLECTION_CREATION_FAILED}: ${res.error}`)
        }
      },
      onError(error) {
        console.error('error', error)
        closeLoading()
        showToast(COLLECTION_CREATION_FAILED)
        throw error
      },
      onSettled: () => {
        queryClient.invalidateQueries(['collection'])
        queryClient.invalidateQueries(['originalCollections'])
      },
    },
  )

  return {
    collections,
    isLoadingCollections,
    defaultCredItems,
    isInitialLoading,
    createCollection,
    defaultCollection,
    collection,
    isInitialLoadingCollecion,
  }
}
