import { Skelton } from 'kai-kit'
import { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { MembershipCard } from '../Membership/MembershipCard'
import { CredItemInput } from '@/@types/credItem'
import { ImageContainer } from '@/components/atom/Images/ImageContainer'
import { CARD_HEIGHT, CARD_WIDTH } from '@/components/templates/credential/AddCredItemContainer'
import { OrganizationWithBackendId } from '@/hooks/useWorkspace'
import { useStateIsCustomImageSelected } from '@/jotai/ui'

type Props = {
  workspace: OrganizationWithBackendId | null
}
export const CredCustomImagePreview: FC<Props> = ({ workspace }) => {
  const [isCustomImageSelected, _] = useStateIsCustomImageSelected()
  const { control } = useFormContext<CredItemInput>()
  const customImagePreview = useWatch({ control, name: 'customImagePreview' })

  return (
    <>
      {isCustomImageSelected ? (
        <>
          {customImagePreview ? (
            <ImageContainer src={customImagePreview} width={'100%'} />
          ) : (
            <Skelton
              isLoading
              width={'100%'}
              height={'188px'}
              radius={'24px'}
              borderWidth={'4px'}
            />
          )}
        </>
      ) : (
        <>
          {workspace && (
            <MembershipCard<CredItemInput>
              width={CARD_WIDTH}
              height={CARD_HEIGHT}
              wName='title'
              wPrimaryColor='primaryColor'
              icon={workspace?.icon || ''}
              wStartDate='startDateInput'
              wEndDate='endDateInput'
              issuerName={workspace?.name || ''}
            />
          )}
        </>
      )}
    </>
  )
}
