import { extractColors } from 'extract-colors'
import { FC, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CredItemInput } from '@/@types/credItem'
import { ColorPickItem } from '@/components/atom/Forms/ColorPickItem'
import { RadioGroup } from '@/components/atom/Forms/RadioGroup'
import { useStateOptionColors } from '@/jotai/ui'
import { getImageData, toHexColor, hue2rgb } from '@/utils/objectUtil'

type Props = {
  targetImage?: string
}
export const CredColorPicker: FC<Props> = ({ targetImage }) => {
  const [imageColors, setImageColors] = useStateOptionColors()

  const { setValue, control } = useFormContext<CredItemInput>()

  const primaryColor = useWatch({ control, name: 'primaryColor' })

  useEffect(() => {
    async function init() {
      await getColors()
    }
    init()
  }, [])

  const getColors = async () => {
    if (!targetImage) return
    try {
      const url = await getImageData(`${targetImage}?rd=${new Date().getTime().toString()}`)
      const colors = (await extractColors(url)).slice(0, 5).map((color) => ({
        ...color,
        hue: color.hue,
        saturation: color.saturation * 0.3,
        lightness: 0.9,
        hex: toHexColor(hue2rgb(color.hue * 360, color.saturation * 30, 90)),
      }))
      colors.forEach((color) => {
        colors.push({
          ...color,
          hue: color.hue,
          saturation: color.saturation * 0.6,
          lightness: 0.2,
          hex: toHexColor(hue2rgb(color.hue * 360, color.saturation * 60, 20)),
        })
      })

      setImageColors(colors)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <RadioGroup
      label='カラー'
      value={primaryColor}
      onChange={(v) => {
        setValue('primaryColor', v)
      }}
    >
      {imageColors?.map((color, index) => (
        <ColorPickItem
          key={'ColorPickItem' + index}
          width='100%'
          value={color.hex}
          color={color}
          isBorder
        />
      ))}
    </RadioGroup>
  )
}
