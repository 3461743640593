import type { StylesConfig } from 'react-select'

export const convertToTagOption = (cnst: string[], addOther: boolean = false): TagOption[] => {
  const options = cnst.map((c) => {
    return { label: c, value: c }
  })
  if (addOther) {
    options.push({ label: 'other', value: 'other' })
  }
  return options
}

export const convertValueToLabel = (options: TagOption[], value: string): string => {
  const option = options.find((o) => o.value === value)
  return option ? option.label : ''
}

export type TagOption = {
  value: string
  label: string
}

export const TAGS: TagOption[] = [
  { value: 'web3', label: 'web3' },
  { value: 'NFT', label: 'NFT' },
  { value: 'Decentralized Identity', label: 'Decentralized Identity' },
  { value: 'DeFi', label: 'DeFi' },
  { value: 'BCG', label: 'BCG' },
  { value: 'GameFi', label: 'GameFi' },
  { value: 'Ceramic', label: 'Ceramic' },
  { value: 'Ethereum', label: 'Ethereum' },
  { value: 'IPFS', label: 'IPFS' },
  { value: 'Polygon', label: 'Polygon' },
  { value: 'Solana', label: 'Solana' },
]

export const EVENT_TAGS: TagOption[] = [
  { value: 'Conference', label: 'Conference' },
  { value: 'Hackathon', label: 'Hackathon' },
]

export const WORK_STYLE_TAGS: TagOption[] = [
  { value: 'remote', label: 'Remote' },
  { value: 'hybrid', label: 'Hybrid' },
  { value: 'on-office', label: 'In-office' },
]

export const WORK_TYPE_TAGS: TagOption[] = [
  { value: 'fulltime', label: 'Fulltime' },
  { value: 'parttime', label: 'Part-time' },
  { value: 'intern', label: 'Intern' },
  { value: 'contributor', label: 'Contributor' },
]

export const JOB_TITLE_TAGS: TagOption[] = [
  { value: 'developer', label: 'developer' },
  { value: 'bizDev', label: 'bizDev' },
  { value: 'sales', label: 'sales' },
  { value: 'marketer', label: 'marketer' },
  { value: 'writer', label: 'writer' },
  { value: 'designer', label: 'designer' },
  { value: 'other', label: 'other' },
]

export const WAGE_UNIT_TAGS: TagOption[] = [
  { value: 'USD', label: 'USD' },
  { value: 'JPY', label: 'JPY' },
]

export const BETA_OPEN_PERIOD: TagOption[] = [
  { value: '2', label: '2 months' },
  { value: '1', label: '1 month' },
]

export const WAGE_PERIOD_TAGS: TagOption[] = [
  { value: 'month', label: 'Month' },
  { value: 'week', label: 'Week' },
  { value: 'day', label: 'Day' },
]

export const getUnitMark = (unit?: string) => {
  if (unit === 'JPY') return '¥'
  return '$'
}

export const getTagOption = (tag: string, options: TagOption[]): TagOption => {
  const tagOp = options.find((t) => t.value === tag)
  return tagOp ? tagOp : { value: tag, label: tag }
}

export const colourStyles = (isDarkMode: boolean): StylesConfig<TagOption, true> => {
  return {
    container: (base) => ({
      width: '100%',
    }),
    control: (styles) => ({
      background: 'none',
      border: 0,
      boxShadow: 'none',
      width: '100%',
      display: 'flex',
    }),
    indicatorSeparator: (base) => ({
      display: 'none',
    }),
    dropdownIndicator: (base) => ({
      display: 'none',
    }),
    multiValue: (base) => ({
      border: `none`,
      background: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
    }),
    multiValueLabel: (base) => ({
      color: `${isDarkMode ? '#ffffff' : '#333333'} !important`,
    }),
  }
}
export const uniqueColourStyles = (
  isDarkMode: boolean,
  hasIcon: boolean = false,
): StylesConfig<TagOption, false> => {
  return {
    container: (base) => ({
      width: '100%',
      paddingLeft: `${hasIcon ? '46px' : base.paddingLeft}`,
    }),
    control: ({ base, prop }) => ({
      background: 'none',
      border: 0,
      margin: 0,
      padding: 0,
      minHeight: 0,
      boxShadow: 'none',
      width: '100%',
    }),
    valueContainer: (props) => ({
      margin: 0,
      padding: 0,
    }),
    // indicatorsContainer: (base) => ({
    //   ...base,
    //   margin: 0,
    //   padding: 0,
    // }),
    // indicatorSeparator: (base) => ({
    //   ...base,
    //   display: 'none',
    // }),
    // dropdownIndicator: (base) => ({
    //   ...base,
    //   display: 'none',
    // }),
    input: (base) => ({
      color: `${isDarkMode ? '#ffffff' : '#333333'} !important`,
    }),
    singleValue: (base) => ({
      border: `none`,
      background: 'none',
      margin: 0,
      padding: 0,
      color: `${isDarkMode ? '#ffffff' : '#333333'} !important`,
    }),
  }
}
