import React, { createContext, FC, useContext } from 'react'
import type { AriaRadioGroupProps } from 'react-aria'
import type { RadioGroupState } from 'react-stately'
import { useRadioGroupState } from 'react-stately'

const RadioGroupContext = createContext<RadioGroupState | undefined>(undefined)

type RadioGroupProviderProps = {
  children: React.ReactNode
  state: RadioGroupState
}

export const RadioGroupProvider: FC<RadioGroupProviderProps> = ({ children, state }) => {
  return <RadioGroupContext.Provider value={state}>{children}</RadioGroupContext.Provider>
}

export const useRadioGroupContext = () => {
  const radioGroupState = useContext(RadioGroupContext)
  return {
    radioGroupState,
  }
}
