import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useToast } from './useToast'
import { MemberWithRoles } from '@/@types/kms'
import { UNAUTHORIZED } from '@/constants/toastMessage'
import { getMember } from '@/lib/kms'
import { isGoodResponse, isUnAuthorized } from '@/utils/http'

export const useVESSMember = (orgId?: string) => {
  const { showToast } = useToast()

  const getMyMember = async (orgId?: string): Promise<MemberWithRoles | undefined> => {
    try {
      if (!orgId) return
      const res = await getMember(orgId)
      if (isGoodResponse(res.status)) {
        const resJson = (await res.json()) as { data: MemberWithRoles | null }
        return resJson.data || undefined
      } else if (isUnAuthorized(res.status)) {
        showToast(UNAUTHORIZED)
        console.log('error', res)
      } else {
        console.log('error', res)
      }
      return
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const { data: member, isInitialLoading: isLoadingMember } = useQuery<MemberWithRoles | undefined>(
    ['getMyMember', orgId],
    () => getMyMember(orgId),
    {
      enabled: !!orgId,
      staleTime: Infinity,
      cacheTime: 300000,
    },
  )

  const isEditor = useMemo(() => {
    if (!member || !member.MemberPermissionRole || member.MemberPermissionRole.length === 0)
      return false
    return (
      member.MemberPermissionRole[0].permissionRole.alias === 'editor' ||
      member.MemberPermissionRole[0].permissionRole.alias === 'admin'
    )
  }, [member])

  return {
    member,
    isLoadingMember,
    isEditor,
  }
}
