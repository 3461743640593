import { useWorkspace } from './useWorkspace'
import { useStateUploadStatus } from '@/jotai/file'

const DWEB_LINK = '.ipfs.w3s.link/'

export const useFileUpload = () => {
  const [status, setStatus] = useStateUploadStatus()
  const { workspace } = useWorkspace()

  const uploadFiles = async (files: File[], dir: 'web3' | 's3' = 'web3') => {
    if (files.length === 0) return
    setStatus('uploading')
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('file', file)
    })

    const options = {
      method: 'POST',
      body: formData,
    }

    try {
      if (dir === 'web3') {
        const res = await fetch('/api/web3StorageUploader', options)
        const data = await res.json()
        if (data.cid) {
          setStatus('completed')
          return `https://${data.cid as string}${DWEB_LINK}`
        } else {
          setStatus('failed')
          return
        }
      } else {
        const res = await fetch('/api/s3Uploader', options)
        const data = await res.json()
        if (data.url) {
          setStatus('completed')
          return data.url as string
        } else {
          setStatus('failed')
          return
        }
      }
    } catch (e) {
      console.error(e)
      setStatus('failed')
    }
  }

  const upload = async (image?: File, forceSaveCompose = false) => {
    if (!image) return
    const url =
      workspace?.useCompose || forceSaveCompose
        ? await uploadFiles([image], 'web3')
        : await uploadFiles([image], 's3')
    if (!url) {
      throw new Error('Failed to upload')
    }
    return url
  }

  const resetUploadStatus = () => {
    setStatus(undefined)
  }

  return {
    status,
    resetUploadStatus,
    upload,
  }
}
