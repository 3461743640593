import styled from '@emotion/styled'
import { Button, Modal, useModal, TextInput } from 'kai-kit'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useCredentials } from '@/hooks/useCredentials'
import { useVESSMember } from '@/hooks/useVESSMember'
import { useWorkspace } from '@/hooks/useWorkspace'

export interface AddCollectionInput {
  title: string
}

export type AddCollectionModalProps = {
  parentId?: string
  credentialType?: string
  modalName?: string
}

export const AddCollectionModal: FC<AddCollectionModalProps> = ({
  parentId,
  credentialType,
  modalName,
  ...props
}) => {
  const { workspace } = useWorkspace()
  const { createCollection } = useCredentials()
  const { closeModal } = useModal()
  const { member } = useVESSMember(workspace?.orgId)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AddCollectionInput>({
    defaultValues: {
      title: '新しいコレクション',
    },
  })

  const onClickSubmit = async (data: AddCollectionInput) => {
    const { title } = data
    if (!workspace || !title || !member) return
    console.log('NewCollection:', {
      name: title,
      saveCompose: workspace.useCompose || false,
      parentId: parentId,
    })
    await createCollection({
      name: title,
      saveCompose: workspace.useCompose || false,
      parentId: parentId,
    })
    closeModal(modalName)
  }

  return (
    <Modal
      isDismissable={true}
      disableClose={isSubmitting}
      title={'コレクションを作成'}
      name={modalName}
      CTA={
        <Button variant='filled' type='submit' form='add-collection' isLoading={isSubmitting}>
          作成する
        </Button>
      }
    >
      <Form id='add-collection' onSubmit={handleSubmit(onClickSubmit)}>
        <TextInput
          label='コレクション名'
          defaultValue='新しいコレクション'
          align='vertical'
          width='100%'
          placeholder='新しいコレクション'
          autoFocus
          {...register('title')}
        />
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--kai-size-sys-space-md);
  background: transparent;
  width: 100%;
`
