import { useStateVESSLoadingModal } from '@/jotai/ui'

export const useVESSLoading = () => {
  const [loading, setLoading] = useStateVESSLoadingModal()

  return {
    loading,
    showLoading: (text?: string) => setLoading({ isLoading: true, text }),
    closeLoading: () => setLoading({ isLoading: false }),
  }
}
