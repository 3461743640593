import { FinalColor } from 'extract-colors/lib/types/Color'
import { atom, useAtom } from 'jotai'
import { BaseToastProps } from '@/components/atom/Toasts/BaseToast'
import { NavigationItemType, NAVIGATION_ITEM } from '@/constants/ui'

export const themeMode = atom<'light' | 'dark'>('light')

export const useStateThemeMode = () => useAtom(themeMode)

export const typeMode = atom<'en' | 'ja'>('en')

export const useStateTypeMode = () => useAtom(typeMode)

export const selectedNavigationItem = atom<NavigationItemType>(NAVIGATION_ITEM.PROFILE)

export const useStateSelectedNavigationItem = () => useAtom(selectedNavigationItem)

export const openBaseDialog = atom<boolean>(false)

export const useStateOpenBaseDialog = () => useAtom(openBaseDialog)

export const openToast = atom<boolean>(false)

export const useStateOpenToast = () => useAtom(openToast)

export const vessLoadingModal = atom<{ isLoading: boolean; text?: string }>({ isLoading: false })

export const useStateVESSLoadingModal = () => useAtom(vessLoadingModal)

export const vessToastProps = atom<BaseToastProps | null>(null)

export const useStateVessToastProps = () => useAtom(vessToastProps)

export const selectedMemberDid = atom<string | undefined>(undefined)

export const useStateSelectedMemberDid = () => useAtom(selectedMemberDid)

export const showHeaderMenu = atom<boolean>(false)

export const useStateShowHeaderMenu = () => useAtom(showHeaderMenu)

export const membershipCardUrl = atom<string>('')

export const useStateMembershipCardUrl = () => useAtom(membershipCardUrl)

export const optionColors = atom<FinalColor[]>([])

export const useStateOptionColors = () => useAtom(optionColors)

export const isCustomImageSelected = atom<boolean>(false)

export const useStateIsCustomImageSelected = () => useAtom(isCustomImageSelected)

export const selectedCredentialId = atom<string | undefined>(undefined)

export const useStateSelectedCredentialId = () => useAtom(selectedCredentialId)
