import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'kai-kit'
import { useToast } from './useToast'
import { useVESSApi } from './useVESSApi'
import { useVESSLoading } from './useVESSLoading'
import { CredItemRequest } from '@/@types/credItem'
import {
  CRED_ITEM_CREATION_SUCCEED,
  CRED_ITEM_CREATION_FAILED,
  CRED_ITEM_DELETE_SUCCEED,
  CRED_ITEM_DELETE_FAILED,
} from '@/constants/toastMessage'
import { DEFAULT_LOADING_MESSAGE } from '@/constants/ui'
import type { VSCredentialItemFrombackupWithUser } from '@/lib/kms'
import { isGoodResponse } from '@/utils/http'

export const useCredential = (id?: string) => {
  const queryClient = useQueryClient()
  const { showToast } = useToast()
  const { showLoading, closeLoading } = useVESSLoading()
  const { addCredItem, fetchCredItem, deleteCredentialItem } = useVESSApi()

  const { data: credItem, isInitialLoading } = useQuery<VSCredentialItemFrombackupWithUser | null>(
    ['credItem', id],
    () => fetchCredItem(id),
    {
      enabled: !!id && id !== '',
      staleTime: Infinity,
      cacheTime: 300000,
    },
  )

  const { mutateAsync: createCredentialItem } = useMutation<any, unknown, CredItemRequest>(
    (param) => addCredItem(param),
    {
      onMutate() {
        showLoading(DEFAULT_LOADING_MESSAGE)
      },
      onSuccess(res) {
        if (isGoodResponse(res.status)) {
          console.log({ res })
          closeLoading()
          showToast(CRED_ITEM_CREATION_SUCCEED)
          return true
        } else {
          closeLoading()
          showToast(`${CRED_ITEM_CREATION_FAILED}: ${res.error}`)
        }
        return false
      },
      onError(error) {
        console.error('error', error)
        closeLoading()
        showToast(CRED_ITEM_CREATION_FAILED)
        throw error
      },
      onSettled: () => {
        queryClient.invalidateQueries(['collection'], { type: 'all', refetchType: 'all' })
        queryClient.invalidateQueries(['defaultCredItems'])
        queryClient.invalidateQueries(['credItem'])
      },
    },
  )

  const { mutateAsync: deleteItem } = useMutation<any, unknown, string>(
    (credItemId) => deleteCredentialItem(credItemId),
    {
      onMutate() {
        showLoading(DEFAULT_LOADING_MESSAGE)
      },
      onSuccess(res) {
        if (isGoodResponse(res.status)) {
          console.log({ res })
          closeLoading()
          showToast(CRED_ITEM_DELETE_SUCCEED)
          return true
        } else {
          closeLoading()
          showToast(`${CRED_ITEM_DELETE_FAILED}: ${res.error}`)
        }
        return false
      },
      onError(error) {
        console.error('error', error)
        closeLoading()
        showToast(CRED_ITEM_CREATION_FAILED)
        throw error
      },
      onSettled: () => {
        queryClient.invalidateQueries(['collection'], { type: 'all', refetchType: 'all' })
        queryClient.invalidateQueries(['defaultCredItems'])
        queryClient.invalidateQueries(['credItem'])
      },
    },
  )

  return {
    credItem,
    isInitialLoading,
    addCredItem,
    deleteItem,
    createCredentialItem,
  }
}
