import styled from '@emotion/styled'
import { IconButton, Text } from 'kai-kit'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import { PiArrowLeftBold } from 'react-icons/pi'
import { FlexHorizontal } from '@/components/atom/Commons/FlexHorizontal'

type Props = {
  variant?: 'default' | 'modal'
  title?: string
  CTA?: React.ReactNode
  showBackButton?: boolean
  onClickBackButton?: () => void
}
export const BasicHeader: FC<Props> = ({
  variant = 'default',
  title = '',
  CTA,
  showBackButton = false,
  onClickBackButton,
}) => {
  const router = useRouter()

  const displayTitle = useMemo(() => {
    if (title.length > 20) {
      return `${title.slice(0, 20)}...`
    }
    return title
  }, [title])

  return (
    <HeaderContainer data-cta={!!CTA} data-variant={variant}>
      <FlexHorizontal gap='32px'>
        {showBackButton && (
          <IconButton
            icon={<PiArrowLeftBold />}
            variant='text'
            color='neutral'
            onPress={onClickBackButton ? () => onClickBackButton() : () => router.back()}
          />
        )}
        <Text as='h1' typo='headline-sm' color='var(--kai-color-sys-on-layer)'>
          {displayTitle}
        </Text>
      </FlexHorizontal>
      {CTA && CTA}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: var(--kai-size-ref-28) var(--kai-size-ref-32) var(--kai-size-ref-20)
    var(--kai-size-ref-32);

  &[data-cta='true'] {
    justify-content: space-between;
  }

  &[data-variant='default'] {
    background: var(--kai-color-sys-background);
    border: none;
  }
  &[data-variant='modal'] {
    background: var(--kai-color-sys-layer-default);
    border-bottom: var(--kai-size-ref-1) solid var(--kai-color-sys-neutral-outline);
  }
`
