import { useTheme } from '@emotion/react'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useThemeMode } from './useThemeMode'
import { Font } from '@/@types/theme'

export const useVESSTheme = () => {
  const theme = useTheme()
  const { themeMode, setLightMode, setDarkMode, initThemeMode, typoMode } = useThemeMode()
  const { t } = useTranslation('common')

  const currentTheme = useMemo(() => {
    return theme.schemes[themeMode]
  }, [themeMode, theme])

  const currentTypo = useMemo(() => {
    return theme.typography['en']
  }, [typoMode, theme])

  const getBasicFont = (font: Font) => {
    return `font-weight:${font.fontWeight}; \n
    font-size: ${font.fontSize}; \n
    line-height: ${font.lineHeight};`
  }

  const getText = (cate: string, name: string) => {
    try {
      return t(`${cate}.${name}`)
    } catch (error) {
      return name
    }
  }

  const currentEffects = theme.effects

  return {
    setLightMode,
    setDarkMode,
    initThemeMode,
    currentTheme,
    currentTypo,
    themeMode,
    getBasicFont,
    getText,
    currentEffects,
  }
}
