export const CopyIcon = () => (
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M26.6667 12H14.6667C13.1939 12 12 13.1939 12 14.6667V26.6667C12 28.1394 13.1939 29.3333 14.6667 29.3333H26.6667C28.1394 29.3333 29.3333 28.1394 29.3333 26.6667V14.6667C29.3333 13.1939 28.1394 12 26.6667 12Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.66675 20.0001H5.33341C4.62617 20.0001 3.94789 19.7191 3.4478 19.219C2.9477 18.7189 2.66675 18.0407 2.66675 17.3334V5.33341C2.66675 4.62617 2.9477 3.94789 3.4478 3.4478C3.94789 2.9477 4.62617 2.66675 5.33341 2.66675H17.3334C18.0407 2.66675 18.7189 2.9477 19.219 3.4478C19.7191 3.94789 20.0001 4.62617 20.0001 5.33341V6.66675'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
