import { Button, Switch } from 'kai-kit'
import { useRef } from 'react'
import { FileTrigger, Button as AriaButton } from 'react-aria-components'
import { Controller, useFormContext } from 'react-hook-form'
import { FaImage } from 'react-icons/fa'
import { CredItemInput } from '@/@types/credItem'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'
import { useStateIsCustomImageSelected } from '@/jotai/ui'

export const CredCustomImagePicker = () => {
  const [isCustomImageSelected, setIsCustomImageSelected] = useStateIsCustomImageSelected()
  const ariaButtonRef = useRef<HTMLButtonElement>(null)

  const { control, setValue } = useFormContext<CredItemInput>()

  const createPreview = (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setValue('customImagePreview', reader.result as string)
    }
  }

  return (
    <FlexVertical gap='8px' width='100%'>
      <Switch
        width='100%'
        variant='fullWidth'
        isSelected={isCustomImageSelected}
        onChange={(flg) => {
          setIsCustomImageSelected(flg)
        }}
      >
        カスタム画像を使用する
      </Switch>
      {isCustomImageSelected && (
        <Controller
          control={control}
          name='customImage'
          render={({ field }) => (
            <FileTrigger
              {...field}
              acceptedFileTypes={['image/*']}
              onSelect={(fileList) => {
                if (!fileList) return
                let files = Array.from(fileList)
                field.onChange(files[0])
                createPreview(files[0])
              }}
            >
              <Button
                width='100%'
                variant='outlined'
                startContent={<FaImage />}
                onPress={() => ariaButtonRef.current?.click()}
                type='button'
              >
                画像を選択する
              </Button>
            </FileTrigger>
          )}
        />
      )}
    </FlexVertical>
  )
}
