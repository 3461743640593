import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { isEthereumAddress, getPkhDIDFromAddress } from 'vess-kit-web'
import { useDIDAccount } from './useDIDAccount'
import { useToast } from './useToast'
import { useVESSLoading } from './useVESSLoading'
import { useVESSMember } from './useVESSMember'
import { useWorkspace } from './useWorkspace'
import { CredItemRequest } from '@/@types/credItem'
import { CredentialsResponse, DataFormat } from '@/@types/credential'
import {
  CreateKmsOrganizationType,
  IssueCollectionRequest,
  MemberWithRoles,
  UpdateOrganizationType,
} from '@/@types/kms'
import { CredType } from '@/components/templates/credential/AddCredItemContainer'
import {
  ADD_ADMIN_FAILED,
  ADD_ADMIN_SUCCEED,
  ORGANIZATION_CREATION_FAILED,
  ORGANIZATION_CREATION_FAILED_PLS_TRY_AGAIN,
  ORGANIZATION_CREATION_SUCCEED,
  UNAUTHORIZED,
  VC_CREATION_FAILED,
  VC_CREATION_SUCCEED,
  VC_UPDATE_FAILED,
  VC_UPDATE_SUCCEED,
} from '@/constants/toastMessage'
import { DEFAULT_LOADING_MESSAGE } from '@/constants/ui'
import {
  CredentialWithHolder,
  VSCredentialItemFromBuckup,
  addMembers,
  createCollection,
  createCredentialItem,
  createKmsOrganizations,
  deleteCredItems,
  deleteMember,
  getAllCollections,
  getCollection,
  getCredentialItem,
  getCredentialsByIssuer,
  getDefaultCollections,
  getKmsSession,
  getMembers,
  issueVerifiableCredentials,
  setRole,
  updateOrganization,
  updateVerifiableCredentials,
} from '@/lib/kms'
import { isGoodResponse, isUnAuthorized } from '@/utils/http'
import { getCurrentDomain } from '@/utils/url'

export interface SubjectUniqueInput {
  id: string
  [key: string]: any
}
export interface IssueCredentialRequest {
  issuerAddress: string
  credTypeName: string
  commonContent: any
  holders: SubjectUniqueInput[]
  credentialItemId: string
  expirationDate?: string
  saveCompose?: boolean
  notifyHolder?: boolean
}

export interface UpdateCredentialRequest {
  issuerAddress: string
  credTypeName: string
  subjects: any[]
  credentialItemId: string
  expirationDate?: string
  saveCompose?: boolean
  notifyHolder?: boolean
}

export interface saveCredentialToComposeDBResponse {
  vc: any
  ceramicId?: string
}

export interface CredentialSubjectProp {
  subject: any
  existId?: string
}

export const useVESSApi = () => {
  const { showLoading, closeLoading } = useVESSLoading()
  const { showToast } = useToast()
  const router = useRouter()
  const queryClient = useQueryClient()
  const { workspace } = useWorkspace()
  const { member } = useVESSMember(workspace?.orgId)
  const { originalAddress } = useDIDAccount()

  const { mutateAsync: createKmsOrganization, isLoading: isCreatingKmsOrganization } = useMutation<
    Response,
    unknown,
    CreateKmsOrganizationType
  >((param) => createKmsOrganizations(param), {
    onMutate() {
      showLoading(DEFAULT_LOADING_MESSAGE)
    },
    async onSuccess(res) {
      if (isGoodResponse(res.status)) {
        closeLoading()
        showToast(ORGANIZATION_CREATION_SUCCEED)
      } else if (isUnAuthorized(res.status)) {
        closeLoading()
        showToast(UNAUTHORIZED)
        console.log('error', res)
        router.push(`/api/auth/logout?returnTo=${getCurrentDomain()}/login`)
      } else {
        closeLoading()
        console.log('error', res)
        showToast(ORGANIZATION_CREATION_FAILED_PLS_TRY_AGAIN)
      }
    },
    onError(error) {
      console.error('error', error)
      closeLoading()
      showToast(ORGANIZATION_CREATION_FAILED)
      throw error
    },
    onSettled: () => {
      queryClient.invalidateQueries(['kmsOrganizations'])
    },
  })

  const getSessionFromKMS = async (orgId: string): Promise<string> => {
    try {
      const res = await getKmsSession(orgId, member?.id)
      if (isGoodResponse(res.status)) {
        const json = await res.json()
        return json.message as string
      } else if (isUnAuthorized(res.status)) {
        showToast(UNAUTHORIZED)
        console.log('error', res)
      } else {
        console.log('error', res)
      }
      throw new Error('Failed to get session')
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const addCollection = async (param: IssueCollectionRequest) => {
    try {
      if (!member?.id || !workspace?.orgId) {
        throw new Error('Failed to get member or organization')
      }
      return await createCollection(workspace?.orgId, member.id, param)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const fetchCredItem = async (id?: string) => {
    if (!id) {
      return null
    }
    try {
      return await getCredentialItem(id, true, workspace?.orgId, member?.id)
    } catch (error) {
      throw error
    }
  }

  const addCredItem = async (param: CredItemRequest) => {
    try {
      if (!member?.id || !workspace?.orgId) {
        throw new Error('Failed to get member or organization')
      }
      return await createCredentialItem(workspace?.orgId, member.id, param)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteCredentialItem = async (credItemId: string) => {
    try {
      if (!member?.id || !workspace?.orgId) {
        throw new Error('Failed to get member or organization')
      }
      return await deleteCredItems(workspace?.orgId, member.id, credItemId)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateWorkspace = async (body: UpdateOrganizationType) => {
    try {
      if (!member?.id || !workspace?.orgId) {
        throw new Error('Failed to get member or organization')
      }
      return await updateOrganization(workspace?.orgId, member.id, body)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const addAdminMembers = async (orgId: string, email: string, role: string): Promise<void> => {
    try {
      if (!member?.id) {
        throw new Error('Failed to get member')
      }
      const res = await addMembers(orgId, member.id, email, role)
      if (isGoodResponse(res.status)) {
        showToast(ADD_ADMIN_SUCCEED)
        queryClient.invalidateQueries(['getAdminMembers', orgId])
        return
      } else if (isUnAuthorized(res.status)) {
        showToast(UNAUTHORIZED)
        throw new Error('Failed to add admin')
      } else {
        console.log('error', res)
      }
      showToast(ADD_ADMIN_FAILED)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const fetchAllCollection = async (id?: string) => {
    if (!id) {
      return []
    }
    try {
      return await getAllCollections(id, member?.id)
    } catch (error) {
      throw error
    }
  }

  const issue = async (
    type: CredType,
    item: VSCredentialItemFromBuckup,
    data: DataFormat[],
    expirationDate?: Date,
    saveCompose?: boolean,
  ): Promise<boolean> => {
    console.log({ originalAddress, workspace })
    if (!originalAddress || !workspace) {
      return false
    }
    showLoading(DEFAULT_LOADING_MESSAGE)
    try {
      console.log({ type })
      console.log({ item })

      let commonContent
      switch (type) {
        case 'attendance':
          commonContent = {
            eventId: item.ceramicId || item.id,
            eventName: item.title,
            eventIcon: item.image,
            startDate: item.startDate ? item.startDate : '',
            endDate: item.endDate ? item.endDate : '',
            sticker:
              item.sticker && item.sticker.length > 0
                ? item.sticker.map((s) => s.image)
                : undefined,
          }
          break
        case 'membership':
          commonContent = {
            organizationName: workspace.name,
            organizationId: workspace.id || workspace.orgId || '',
            organizationIcon: workspace.icon || '',
            membershipName: item.title,
            membershipIcon: item.image,
            startDate: item.startDate ? item.startDate : '',
            endDate: item.endDate ? item.endDate : '',
            sticker:
              item.sticker && item.sticker.length > 0
                ? item.sticker.map((s) => s.image)
                : undefined,
          }
          break
        case 'certificate':
          commonContent = {
            certificationId: item.ceramicId || item.id,
            certificationName: item.title,
            image: item.image || '',
            startDate: item.startDate ? item.startDate : '',
            endDate: item.endDate ? item.endDate : '',
            sticker:
              item.sticker && item.sticker.length > 0
                ? item.sticker.map((s) => s.image)
                : undefined,
          }
          break

        default:
          break
      }

      const subjectUniqueInputs = data.map((d) => {
        const { did, email, ...otherInfo } = d
        const formatedDid = isEthereumAddress(did) ? getPkhDIDFromAddress(did) : did.toLowerCase()
        return {
          id: formatedDid,
          ...otherInfo,
        }
      })
      const body: IssueCredentialRequest = {
        issuerAddress: originalAddress,
        credTypeName: type,
        commonContent: commonContent,
        holders: subjectUniqueInputs,
        credentialItemId: item.id,
        expirationDate: expirationDate ? expirationDate?.toISOString() : undefined,
        saveCompose: saveCompose,
        notifyHolder: true,
      }
      const res = await issueVerifiableCredentials(body, workspace.orgId, member?.id)
      if (!res) {
        throw new Error('res is undefined')
      }
      if (!isGoodResponse(res.status)) {
        throw new Error(`Failed to issue credential: ${res.statusText}`)
      }
      const resJson = await res.json()
      console.log({ resJson })
      const vcs = resJson.data as saveCredentialToComposeDBResponse[]
      console.log({ vcs })
      queryClient.invalidateQueries(['credItem', item.id])
      if (vcs && vcs.length > 0) {
        closeLoading()
        showToast(VC_CREATION_SUCCEED)
        return true
      }
      closeLoading()
      showToast(VC_CREATION_FAILED)
      return false
    } catch (error) {
      console.error('error', error)
      closeLoading()
      showToast(VC_CREATION_FAILED)
      throw error
    }
  }

  const update = async (
    credential: CredentialWithHolder,
    item: VSCredentialItemFromBuckup,
    expirationDate?: Date,
    saveCompose?: boolean,
  ): Promise<boolean> => {
    console.log({ originalAddress, workspace })
    if (!originalAddress || !workspace) {
      return false
    }
    showLoading(DEFAULT_LOADING_MESSAGE)
    try {
      console.log({ credential })

      const cred = JSON.parse(credential.plainCredential)
      const subjects: CredentialSubjectProp = {
        subject: cred.credentialSubject,
        existId: credential.id,
      }
      const body: UpdateCredentialRequest = {
        issuerAddress: originalAddress,
        credTypeName: item.credentialType.name as CredType,
        subjects: [subjects],
        credentialItemId: item.id,
        expirationDate: expirationDate ? expirationDate?.toISOString() : undefined,
        saveCompose: saveCompose,
        notifyHolder: true,
      }
      const res = await updateVerifiableCredentials(body, workspace.orgId, member?.id)
      if (!res) {
        throw new Error('res is undefined')
      }
      const resJson = await res.json()
      console.log({ resJson })
      const vcs = resJson.data as saveCredentialToComposeDBResponse[]
      console.log({ vcs })
      queryClient.invalidateQueries(['credItem', item.id])
      if (vcs && vcs.length > 0) {
        closeLoading()
        showToast(VC_UPDATE_SUCCEED)
        return true
      }
      closeLoading()
      showToast(VC_UPDATE_FAILED)
      return false
    } catch (error) {
      console.error('error', error)
      closeLoading()
      showToast(VC_UPDATE_FAILED)
      throw error
    }
  }

  const getAdminMembers = async (orgId?: string): Promise<MemberWithRoles[]> => {
    try {
      if (!member?.id) {
        throw new Error('Failed to get member')
      }
      if (!orgId) return []
      let result: MemberWithRoles[] = []
      const res = await getMembers(orgId, member.id)
      if (isGoodResponse(res.status)) {
        result = (await res.json()) as MemberWithRoles[]
      } else if (isUnAuthorized(res.status)) {
        showToast(UNAUTHORIZED)
        console.log('error', res)
      } else {
        console.log('error', res)
      }
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const setMemberRole = async (targetMemberId: string, role: string) => {
    if (!workspace?.orgId || !member?.id) {
      throw new Error('Failed to get member or organization')
    }
    try {
      console.log({ targetMemberId, role })
      return await setRole(workspace.orgId, member.id, targetMemberId, role)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteMemberRole = async (targetMemberId: string) => {
    if (!workspace?.orgId || !member?.id) {
      throw new Error('Failed to get member or organization')
    }
    try {
      return await deleteMember(workspace.orgId, member?.id, targetMemberId)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getCredentials = async (did?: string): Promise<CredentialsResponse | null> => {
    if (!did) return null
    const res = await getCredentialsByIssuer(did, workspace?.orgId, member?.id)
    return (await res.json()) as CredentialsResponse
  }

  const fetchCollection = async (id?: string) => {
    if (!id || !workspace?.orgId || !member?.id) {
      return null
    }
    try {
      return await getCollection(workspace.orgId, member.id, id)
    } catch (error) {
      throw error
    }
  }

  const fetchDefaultCredItem = async (id?: string) => {
    if (!id) {
      return null
    }
    try {
      return await getDefaultCollections(id, member?.id)
    } catch (error) {
      throw error
    }
  }

  const { data: admins, isInitialLoading: isLoadingAdmins } = useQuery<MemberWithRoles[]>(
    ['getAdminMembers', workspace?.orgId],
    () => getAdminMembers(workspace?.orgId),
    {
      enabled: !!workspace?.orgId && !!member?.id,
      staleTime: Infinity,
      cacheTime: 300000,
    },
  )

  return {
    createKmsOrganization,
    isCreatingKmsOrganization,
    getSessionFromKMS,
    addAdminMembers,
    admins,
    isLoadingAdmins,
    issue,
    update,
    getCredentials,
    fetchCollection,
    fetchDefaultCredItem,
    fetchAllCollection,
    addCollection,
    addCredItem,
    fetchCredItem,
    deleteCredentialItem,
    updateWorkspace,
    setMemberRole,
    deleteMemberRole,
  }
}
