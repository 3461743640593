import { FlexHorizontal, Button, Text } from 'kai-kit'
import { useRef, useState } from 'react'
import { FileTrigger } from 'react-aria-components'
import { Controller, useFormContext } from 'react-hook-form'
import { FaImage } from 'react-icons/fa'
import { CredItemInput } from '@/@types/credItem'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'

export const StickerImagePicker = () => {
  const ariaButtonRef = useRef<HTMLButtonElement>(null)
  const [previews, setPreviews] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)

  const { control, setValue } = useFormContext<CredItemInput>()

  const createPreviews = (files: File[]) => {
    const newPreviews: string[] = []
    files.forEach((file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        newPreviews.push(reader.result as string)
        if (newPreviews.length === files.length) {
          setPreviews(newPreviews)
          setValue('stickerPreviews', newPreviews)
        }
      }
    })
  }

  return (
    <FlexVertical gap='8px' width='100%'>
      <Controller
        control={control}
        name='stickerFiles'
        render={({ field }) => (
          <FileTrigger
            {...field}
            allowsMultiple
            acceptedFileTypes={['image/*']}
            onSelect={(fileList) => {
              if (!fileList) return
              let files = Array.from(fileList)
              if (files.length > 5) {
                setError('画像は5つまでしか選択できません')
                return
              }
              setError(null)
              field.onChange(files)
              createPreviews(files)
            }}
          >
            <Button
              width='100%'
              variant='outlined'
              startContent={<FaImage />}
              onPress={() => ariaButtonRef.current?.click()}
              type='button'
            >
              ステッカーを登録する(5つまで)
            </Button>
          </FileTrigger>
        )}
      />
      {error && <Text color='red'>{error}</Text>}
      <FlexHorizontal gap='8px' width='100%'>
        {previews.map((preview, index) => (
          <img key={index} src={preview} alt={`preview-${index}`} width='50px' />
        ))}
      </FlexHorizontal>
    </FlexVertical>
  )
}
