import { useModal, Modal } from 'kai-kit'
import { Router, useRouter } from 'next/router'
import { FC, useEffect, useMemo } from 'react'
import { FullContentLayout } from '../atom/layouts/FullContentLayout'
import { NCLayout } from '../atom/layouts/NCLayout'
import LoadingModal from '../organism/Modal/LoadingModal'
import { NavigationList } from '../organism/Navigation/NavigationList'
import { useVESSLoading } from '@/hooks/useVESSLoading'
import { useVESSTheme } from '@/hooks/useVESSTheme'

type Props = {
  children: React.ReactNode
}
export const BasicLayout: FC<Props> = ({ children }) => {
  const { loading } = useVESSLoading()
  const { initThemeMode } = useVESSTheme()
  const { openModal, closeModal } = useModal()
  const router = useRouter()
  const isFullPageLayout = useMemo(() => {
    return (
      router.asPath.startsWith('/kms/workspace') ||
      router.asPath.startsWith('/workspace/edit') ||
      router.asPath.startsWith('/login') ||
      router.asPath.startsWith('/kms/callback') ||
      router.asPath.startsWith('/credential/add') ||
      router.asPath.startsWith('/credential/detail/distribute')
    )
  }, [router])

  useEffect(() => {
    initThemeMode()
  }, [])
  useEffect(() => {
    const start = () => {
      openModal('pageTransitionModal')
    }
    const end = () => {
      closeModal('pageTransitionModal')
    }
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])
  return (
    <>
      {!isFullPageLayout ? (
        <NCLayout
          navigation={<NavigationList />}
          backgroundColor={'var(--kai-color-sys-background)'}
          height='100vh'
        >
          {children}
        </NCLayout>
      ) : (
        <FullContentLayout>{children}</FullContentLayout>
      )}
      <>{loading.isLoading && <LoadingModal text={loading.text} />}</>
    </>
  )
}
