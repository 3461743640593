import styled from '@emotion/styled'
import { Text } from 'kai-kit'
import { ComponentPropsWithoutRef, FC, ReactNode } from 'react'
import { useVESSTheme } from '@/hooks/useVESSTheme'

interface Props extends ComponentPropsWithoutRef<'button'> {
  icon: ReactNode
  title: string
  selected?: boolean
}

export const NavigationItem: FC<Props> = ({ icon, title, selected = false, ...props }) => {
  const { getText } = useVESSTheme()

  return (
    <NavigationItemContainer data-selected={selected} {...props}>
      <NavigationItemLayer data-selected={selected}>
        {icon}
        <Text
          as='span'
          color={selected ? 'var(--kai-color-sys-on-layer)' : 'var(--kai-color-sys-on-layer-minor)'}
          typo='title-md'
        >
          {getText('Common', title)}
        </Text>
      </NavigationItemLayer>
    </NavigationItemContainer>
  )
}

const NavigationItemContainer = styled.button`
  background: transparent;
  border-radius: var(--kai-size-sys-round-md);
  border: none;
  display: flex;
  flex-direction: row;
  gap: var(--kai-size-sys-space-sm);
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  padding: var(--kai-size-sys-space-md) var(--kai-size-sys-space-lg);
  width: 100%;
  transition: all var(--kai-motion-sys-duration-medium) var(--kai-motion-sys-easing-standard);
  @media (max-width: 599px) {
    max-width: 180px;
    padding: 8px 12px;
  }

  &:hover {
    background: var(--kai-color-sys-layer-default);
    cursor: pointer;
  }

  &[data-selected='true'] {
    background: var(--kai-color-sys-layer-nearer);
    cursor: pointer;
  }
`

const NavigationItemLayer = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--kai-size-sys-space-sm);
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: fit-content;
  color: var(--kai-color-sys-on-layer-minor);

  &[data-selected='true'] {
    color: var(--kai-color-sys-on-layer);
  }
`
