export const CVOXEL_CREATION_SUCCEED = 'Work Credential Created Successfully!'
export const CVOXEL_CREATION_FAILED = 'Failed to Create Credential...'

export const CVOXEL_VERIFY_SUCCEED = 'Verified Successfully!'
export const CVOXEL_VERIFY_FAILED = 'Failed to Verify Credential...'

export const CVOXEL_UPDATE_SUCCEED = 'Updated Successfully!'
export const CVOXEL_UPDATE_FAILED = 'Failed to Update Credential...'

export const ORGANIZATION_CREATION_SUCCEED = 'New Workspace Created Successfully!'
export const ORGANIZATION_CREATION_FAILED = 'Failed to Create Workspace...'
export const ORGANIZATION_CREATION_FAILED_PLS_TRY_AGAIN =
  'Failed to Create Workspace. Please try again.'

export const ORGANIZATION_UPDATE_SUCCEED = 'Successfully updated the workspace!'
export const ORGANIZATION_UPDATE_FAILED = 'Failed to update the workspace...'

export const MEMBERSHIP_CREATION_SUCCEED = 'New Role Created Successfully!'
export const MEMBERSHIP_CREATION_FAILED = 'Failed to Create New Role...'

export const MEMBERSHIP_DELETION_SUCCEED = 'The Role Deleted Successfully!'
export const MEMBERSHIP_DELETION_FAILED = 'Failed to Delete Role...'

export const MEMBERSHIP_SUBJECT_CREATION_SUCCEED = 'Successfully assigned membership!'
export const MEMBERSHIP_SUBJECT_CREATION_FAILED = 'Failed to assign membership...'

export const MEMBERSHIP_SUBJECT_UPDATE_SUCCEED = 'Successfully updated the membership!'
export const MEMBERSHIP_SUBJECT_UPDATE_FAILED = 'Failed to update the membership...'

export const EVENT_CREATION_SUCCEED = 'Successfully created new event!'
export const EVENT_CREATION_FAILED = 'Failed to create event...'

export const EVENT_UPDATE_SUCCEED = 'Successfully updated the event!'
export const EVENT_UPDATE_FAILED = 'Failed to update the event...'

export const EVENT_ATTENDANCE_CREATION_SUCCEED = 'Successfully issued event attendance!'
export const EVENT_ATTENDANCE_CREATION_FAILED = 'Failed to issue event attendance...'

export const EVENT_ATTENDANCE_HELD_SUCCEED = 'Successfully got event attendance!'
export const EVENT_ATTENDANCE_HELD_FAILED = 'Failed to get event attendance...'

export const UNAUTHORIZED = 'Sorry, You are not authorized.'
export const ADD_ADMIN_SUCCEED = 'Successfully added new member'
export const ADD_ADMIN_FAILED = 'Failed to add new member'

export const JOB_CREATION_SUCCEED = 'Successfully create new job post!'
export const JOB_CREATION_FAILED = 'Failed to create job post...'
export const JOB_UPDATE_SUCCEED = 'Successfully updated job post!'
export const JOB_UPDATE_FAILED = 'Failed to update job post...'

export const CERTIFICATION_CREATION_SUCCEED = 'Successfully created new certification!'
export const CERTIFICATION_CREATION_FAILED = 'Failed to create certification...'

export const COLLECTION_CREATION_SUCCEED = 'Successfully created new collection!'
export const COLLECTION_CREATION_FAILED = 'Failed to create collection...'

export const CRED_ITEM_CREATION_SUCCEED = 'Successfully created the new item!'
export const CRED_ITEM_CREATION_FAILED = 'Failed to create the new item...'

export const CRED_ITEM_DELETE_SUCCEED = 'Successfully deleted the new item!'
export const CRED_ITEM_DELETE_FAILED = 'Failed to delete the new item...'

export const VC_CREATION_SUCCEED = 'Successfully issued new VCs!'
export const VC_CREATION_FAILED = 'Failed to issue VCs...'

export const VC_UPDATE_SUCCEED = 'Successfully updated the VCs!'
export const VC_UPDATE_FAILED = 'Failed to update VCs...'

export const CERTIFICATION_UPDATE_SUCCEED = 'Successfully updated the certification!'
export const CERTIFICATION_UPDATE_FAILED = 'Failed to update the certification...'

export const CERTIFICATION_SUBJECT_CREATION_SUCCEED = 'Successfully issued certification VC!'
export const CERTIFICATION_SUBJECT_CREATION_FAILED = 'Failed to issue certification VC...'

export const SWITCH_ROLE_SUCCEED = 'Successfully changed the role'
export const SWITCH_ROLE_FAILED = 'Failed to change the role...'

export const DELETE_ROLE_SUCCEED = 'Successfully deleted the role'
export const DELETE_ROLE_FAILED = 'Failed to delete the role...'
