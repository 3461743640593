import { PrimitiveTheme, ADSColors, Typographies, Effects } from '@/@types/theme'

export interface ADSTheme {
  schemes: ADSColors
  typography: Typographies
  effects: Effects
}

export const primitiveTheme: PrimitiveTheme = {
  prim: {
    colors: {
      black: '000000',
      white: 'ffffff',
      primary0: '#000000',
      primary10: '#320046',
      primary20: '#530070',
      primary25: '#640086',
      primary30: '#75009D',
      primary35: '#8700B5',
      primary40: '#9A00CD',
      primary50: '#B636E8',
      primary60: '#D15BFF',
      primary70: '#E089FF',
      primary80: '#EDB1FF',
      primary90: '#F9D8FF',
      primary95: '#FEEBFF',
      primary98: '#FFF7FB',
      primary99: '#FFFBFF',
      primary100: '#FFFFFF',
      secondary0: '#000000',
      secondary10: '#001D33',
      secondary20: '#003354',
      secondary25: '#003E66',
      secondary30: '#004A78',
      secondary35: '#00568A',
      secondary40: '#00629D',
      secondary50: '#277CBC',
      secondary60: '#4996D8',
      secondary70: '#67B1F4',
      secondary80: '#99CBFF',
      secondary90: '#CFE5FF',
      secondary95: '#E8F1FF',
      secondary98: '#F7F9FF',
      secondary99: '#FCFCFF',
      secondary100: '#FFFFFF',
      tertiary0: '#000000',
      tertiary10: '#00201E',
      tertiary20: '#003734',
      tertiary25: '#004340',
      tertiary30: '#00504C',
      tertiary35: '#005D58',
      tertiary40: '#006A64',
      tertiary50: '#00857F',
      tertiary60: '#00A29A',
      tertiary70: '#25BEB5',
      tertiary80: '#4FDBD1',
      tertiary90: '#71F7ED',
      tertiary95: '#B1FFF7',
      tertiary98: '#E4FFFB',
      tertiary99: '#F2FFFC',
      tertiary100: '#FFFFFF',
      error0: '#000000',
      error10: '#410002',
      error20: '#690005',
      error25: '#7E0007',
      error30: '#93000A',
      error35: '#A80710',
      error40: '#BA1A1A',
      error50: '#DE3730',
      error60: '#FF5449',
      error70: '#FF897D',
      error80: '#FFB4AB',
      error90: '#FFDAD6',
      error95: '#FFDEEA',
      error98: '#FFF8F7',
      error99: '#FFFBFF',
      error100: '#FFFFFF',
      neutral0: '#000000',
      neutral4: '#100D10',
      neutral6: '#151216',
      neutral10: '#1E1B1E',
      neutral12: '#221F22',
      neutral17: '#2C292C',
      neutral20: '#332F33',
      neutral22: '#373437',
      neutral25: '#3E3A3E',
      neutral30: '#4A4549',
      neutral35: '#565155',
      neutral40: '#625D61',
      neutral50: '#7B7579',
      neutral60: '#958F93',
      neutral70: '#B0A9AE',
      neutral80: '#CCC4C9',
      neutral90: '#E8E0E5',
      neutral92: '#EEE6EA',
      neutral94: '#F4ECF0',
      neutral95: '#F7EFF3',
      neutral96: '#F9F1F6',
      neutral98: '#FFF7FB',
      neutral99: '#FFFBFF',
      neutral100: '#FFFFFF',
      neutralVariant0: '#000000',
      neutralVariant10: '#201A21',
      neutralVariant20: '#352E36',
      neutralVariant25: '#403941',
      neutralVariant30: '#4C444D',
      neutralVariant35: '#585058',
      neutralVariant40: '#645C64',
      neutralVariant50: '#7D747D',
      neutralVariant60: '#988E97',
      neutralVariant70: '#B3A8B2',
      neutralVariant80: '#CFC3CD',
      neutralVariant90: '#EBDFE9',
      neutralVariant95: '#FAEDF8',
      neutralVariant98: '#FFF7FB',
      neutralVariant99: '#FFFBFF',
      neutralVariant100: '#FFFFFF',
    },
  },
}

export const theme: ADSTheme = {
  schemes: {
    light: {
      surfaceTint: primitiveTheme.prim.colors.primary40,
      onErrorContainer: primitiveTheme.prim.colors.error10,
      onError: primitiveTheme.prim.colors.error100,
      errorContainer: primitiveTheme.prim.colors.error90,
      onTertiaryContainer: primitiveTheme.prim.colors.tertiary10,
      onTertiary: primitiveTheme.prim.colors.tertiary100,
      tertiaryContainer: primitiveTheme.prim.colors.tertiary90,
      tertiary: primitiveTheme.prim.colors.tertiary40,
      shadow: primitiveTheme.prim.colors.black,
      error: primitiveTheme.prim.colors.error40,
      outline: primitiveTheme.prim.colors.neutralVariant50,
      outlineVariant: primitiveTheme.prim.colors.neutralVariant80,
      onBackground: primitiveTheme.prim.colors.neutral10,
      background: primitiveTheme.prim.colors.neutral99,
      inverseOnSurface: primitiveTheme.prim.colors.neutral95,
      inverseSurface: primitiveTheme.prim.colors.neutral20,
      onSurfaceVariant: primitiveTheme.prim.colors.neutralVariant30,
      onSurface: primitiveTheme.prim.colors.neutral10,
      surfaceVariant: primitiveTheme.prim.colors.neutralVariant90,
      surface: primitiveTheme.prim.colors.neutral99,
      onSecondaryContainer: primitiveTheme.prim.colors.secondary10,
      onSecondary: primitiveTheme.prim.colors.secondary100,
      secondaryContainer: primitiveTheme.prim.colors.secondary90,
      secondaryContainerLight: primitiveTheme.prim.colors.secondary80,
      secondaryContainerLightest: primitiveTheme.prim.colors.secondary70,
      secondary: primitiveTheme.prim.colors.secondary40,
      secondaryHovered: primitiveTheme.prim.colors.secondary35,
      secondaryFocused: primitiveTheme.prim.colors.secondary30,
      inversePrimary: primitiveTheme.prim.colors.primary80,
      onPrimaryContainer: primitiveTheme.prim.colors.primary10,
      onPrimary: primitiveTheme.prim.colors.primary100,
      primaryContainer: primitiveTheme.prim.colors.primary90,
      primaryContainerLight: primitiveTheme.prim.colors.primary80,
      primaryContainerLightest: primitiveTheme.prim.colors.primary70,
      primary: primitiveTheme.prim.colors.primary40,
      primaryHovered: primitiveTheme.prim.colors.primary35,
      primaryFocused: primitiveTheme.prim.colors.primary30,
      surfaceContainerHighest: primitiveTheme.prim.colors.neutral90,
      surfaceContainerHigh: primitiveTheme.prim.colors.neutral92,
      surfaceContainer: primitiveTheme.prim.colors.neutral94,
      surfaceContainerLow: primitiveTheme.prim.colors.neutral96,
      surfaceContainerLowest: primitiveTheme.prim.colors.neutral100,
      white: primitiveTheme.prim.colors.white,
      black: primitiveTheme.prim.colors.black,

      // These will be depricated
      primaryContainerOpacity10: 'rgba(117, 0, 157, 0.1)',
      primaryContainerOpacity20: 'rgba(117, 0, 157, 0.2)',
      primaryContainerOpacity40: 'rgba(117, 0, 157, 0.4)',
      onPrimaryContainerOpacity10: 'rgba(117, 0, 157, 0.1)',
      onPrimaryContainerOpacity20: 'rgba(117, 0, 157, 0.2)',
      onPrimaryContainerOpacity40: 'rgba(117, 0, 157, 0.4)',
      surface1: '#FCF2EAff',
      surface2: '#FCF2EAff',
      surface3: '#F6ECE4FF',
      surface4: '#F0E6DEff',
      surface5: '#EAE1D9ff',
      depth1: '#FCF2EAff',
      depth2: '#FCF2EAff',
      depth3: '#FCF2EAff',
      depth4: '#FFFFFFff',
      bodyBackground: '#ffffffff',
    },
    dark: {
      surfaceTint: primitiveTheme.prim.colors.primary80,
      onErrorContainer: primitiveTheme.prim.colors.error90,
      onError: primitiveTheme.prim.colors.error20,
      errorContainer: primitiveTheme.prim.colors.error30,
      onTertiaryContainer: primitiveTheme.prim.colors.tertiary90,
      onTertiary: primitiveTheme.prim.colors.tertiary20,
      tertiaryContainer: primitiveTheme.prim.colors.tertiary30,
      tertiary: primitiveTheme.prim.colors.tertiary80,
      shadow: primitiveTheme.prim.colors.black,
      error: primitiveTheme.prim.colors.error80,
      outline: primitiveTheme.prim.colors.neutralVariant60,
      outlineVariant: primitiveTheme.prim.colors.neutralVariant30,
      onBackground: primitiveTheme.prim.colors.neutral90,
      background: primitiveTheme.prim.colors.neutral6,
      inverseOnSurface: primitiveTheme.prim.colors.neutral10,
      inverseSurface: primitiveTheme.prim.colors.neutral90,
      onSurfaceVariant: primitiveTheme.prim.colors.neutralVariant80,
      onSurface: primitiveTheme.prim.colors.neutral90,
      surfaceVariant: primitiveTheme.prim.colors.neutralVariant30,
      surface: primitiveTheme.prim.colors.neutral6,
      onSecondaryContainer: primitiveTheme.prim.colors.secondary90,
      onSecondary: primitiveTheme.prim.colors.secondary20,
      secondaryContainer: primitiveTheme.prim.colors.secondary30,
      secondaryContainerLight: primitiveTheme.prim.colors.secondary40,
      secondaryContainerLightest: primitiveTheme.prim.colors.secondary50,
      secondary: primitiveTheme.prim.colors.secondary80,
      secondaryHovered: primitiveTheme.prim.colors.secondary90,
      secondaryFocused: primitiveTheme.prim.colors.secondary95,
      inversePrimary: primitiveTheme.prim.colors.primary40,
      onPrimaryContainer: primitiveTheme.prim.colors.primary90,
      onPrimary: primitiveTheme.prim.colors.primary20,
      primaryContainer: primitiveTheme.prim.colors.primary30,
      primaryContainerLight: primitiveTheme.prim.colors.primary40,
      primaryContainerLightest: primitiveTheme.prim.colors.primary50,
      primary: primitiveTheme.prim.colors.primary80,
      primaryHovered: primitiveTheme.prim.colors.primary90,
      primaryFocused: primitiveTheme.prim.colors.primary95,
      surfaceContainerHighest: primitiveTheme.prim.colors.neutral22,
      surfaceContainerHigh: primitiveTheme.prim.colors.neutral17,
      surfaceContainer: primitiveTheme.prim.colors.neutral12,
      surfaceContainerLow: primitiveTheme.prim.colors.neutral10,
      surfaceContainerLowest: primitiveTheme.prim.colors.neutral4,
      white: primitiveTheme.prim.colors.white,
      black: primitiveTheme.prim.colors.black,

      // These will be depricated
      primaryContainerOpacity10: 'rgba(117, 0, 157, 0.1)',
      primaryContainerOpacity20: 'rgba(117, 0, 157, 0.2)',
      primaryContainerOpacity40: 'rgba(117, 0, 157, 0.4)',
      onPrimaryContainerOpacity10: 'rgba(249, 216, 255, 0.1)',
      onPrimaryContainerOpacity20: 'rgba(249, 216, 255, 0.2)',
      onPrimaryContainerOpacity40: 'rgba(249, 216, 255, 0.4)',
      surface1:
        'linear-gradient(0deg, rgba(237, 177, 255, 0.05), rgba(237, 177, 255, 0.05)), #1E1B1E',
      surface2:
        'linear-gradient(0deg, rgba(237, 177, 255, 0.08), rgba(237, 177, 255, 0.08)), #1E1B1E',
      surface3:
        'linear-gradient(0deg, rgba(237, 177, 255, 0.11), rgba(237, 177, 255, 0.11)), #1E1B1E',
      surface4:
        'linear-gradient(0deg, rgba(237, 177, 255, 0.12), rgba(237, 177, 255, 0.12)), #1E1B1E',
      surface5:
        'linear-gradient(0deg, rgba(237, 177, 255, 0.14), rgba(237, 177, 255, 0.14)), #1E1B1E',
      depth1: '#262228',
      depth2: '#242127',
      depth3: '#221F26',
      depth4: '#211F25',
      bodyBackground: '#000000ff',
    },
  },
  typography: {
    en: {
      display: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '3.5625rem',
          lineHeight: '4rem',
          fontWeight: 500,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '2.8125rem',
          lineHeight: '3.25rem',
          fontWeight: 500,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '2.25rem',
          lineHeight: '2.75rem',
          fontWeight: 600,
        },
      },
      headLine: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '2rem',
          lineHeight: '2.5rem',
          fontWeight: 600,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '1.75rem',
          lineHeight: '2.25rem',
          fontWeight: 600,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '1.5rem',
          lineHeight: '2rem',
          fontWeight: 600,
        },
      },
      title: {
        exLarge: {
          fontFamily: 'Noto Sans',
          fontSize: '1.375rem',
          lineHeight: '1.75rem',
          fontWeight: 500,
        },
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '1.125rem',
          lineHeight: '1.5625rem',
          fontWeight: 600,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '1rem',
          lineHeight: '1.4375rem',
          fontWeight: 600,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 600,
        },
      },
      label: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 600,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 500,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.6875rem',
          lineHeight: '1rem',
          fontWeight: 600,
        },
      },
      body: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          fontWeight: 400,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 400,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 500,
        },
      },
      bodyBold: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          fontWeight: 700,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 700,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 700,
        },
      },
    },
    ja: {
      display: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '3.125rem',
          lineHeight: '4rem',
          fontWeight: 700,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '2.50rem',
          lineHeight: '3.25rem',
          fontWeight: 500,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '1.875rem',
          lineHeight: '2,75rem',
          fontWeight: 600,
        },
      },
      headLine: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '1.625rem',
          lineHeight: '2.5rem',
          fontWeight: 700,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '1.375rem',
          lineHeight: '2.25rem',
          fontWeight: 700,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '1.25rem',
          lineHeight: '2rem',
          fontWeight: 700,
        },
      },
      title: {
        exLarge: {
          fontFamily: 'Noto Sans',
          fontSize: '1.25rem',
          lineHeight: '1.75rem',
          fontWeight: 500,
        },
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '1.0625rem',
          lineHeight: '1.5625rem',
          fontWeight: 600,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.9375rem',
          lineHeight: '1.4375rem',
          fontWeight: 600,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.8125rem',
          lineHeight: '1.25rem',
          fontWeight: 600,
        },
      },
      label: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '0.8125rem',
          lineHeight: '1.25rem',
          fontWeight: 700,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.6875rem',
          lineHeight: '1rem',
          fontWeight: 700,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.625rem',
          lineHeight: '1rem',
          fontWeight: 700,
        },
      },
      body: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: 400,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 400,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 500,
        },
      },
      bodyBold: {
        large: {
          fontFamily: 'Noto Sans',
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: 700,
        },
        medium: {
          fontFamily: 'Noto Sans',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 700,
        },
        small: {
          fontFamily: 'Noto Sans',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 900,
        },
      },
    },
  },
  effects: {
    cardShadow: `0px 2px 0px 0px #afa9ad`,
  },
}
