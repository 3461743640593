import styled from '@emotion/styled'
import { Button, Text, TextArea, TextInput } from 'kai-kit'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaImage } from 'react-icons/fa'
import { CredItemInput, CredItemRequest } from '@/@types/credItem'
import { FlexHorizontal } from '@/components/atom/Commons/FlexHorizontal'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'
import { BaseDatePicker } from '@/components/atom/Forms/BaseDatePicker'
import { TagSelect } from '@/components/atom/Forms/TagSelect'
import { ArticleContentLayout } from '@/components/atom/layouts/ArticleContentLayout'
import { HCLayout } from '@/components/atom/layouts/HCLayout'
import { CredColorPicker } from '@/components/molecule/Credential/CredColorPicker'
import { CredCustomImagePicker } from '@/components/molecule/Credential/CredCustomImagePicker'
import { CredCustomImagePreview } from '@/components/molecule/Credential/CredCustomImagePreview'
import { StickerImagePicker } from '@/components/molecule/Credential/StickerImagePicker'
import { GenerateCredentialImage } from '@/components/molecule/Membership/MembershipCard'
import { BasicHeader } from '@/components/organism/Headers/BasicHeader'
import { ExpirationForm } from '@/components/organism/form/ExpirationForm'
import { DEFAULT_LOADING_MESSAGE } from '@/constants/ui'
import { useCredential } from '@/hooks/useCredential'
import { useCredentials } from '@/hooks/useCredentials'
import { useFileUpload } from '@/hooks/useFileUpload'
import { useVESSLoading } from '@/hooks/useVESSLoading'
import { useVESSMember } from '@/hooks/useVESSMember'
import { useVESSTheme } from '@/hooks/useVESSTheme'
import { useWorkspace } from '@/hooks/useWorkspace'
import { useStateIsCustomImageSelected } from '@/jotai/ui'
import { formatDate } from '@/utils/date'

/* TODO:
 * [ ] Add validation for the form
 * [ ] Add onSubmitHandler
 * [ ] Make select initial color for the primary color of image
 */
export const CredTypeUnion = ['membership', 'attendance', 'certificate', 'default'] as const
export type CredType = typeof CredTypeUnion[number]
export type CredTypeProps = {
  type?: CredType
}

export const CARD_WIDTH = 280 * 4
export const CARD_HEIGHT = 176 * 4

export const AddCredItemContainer: FC<CredTypeProps> = ({ type }) => {
  const { workspace } = useWorkspace()
  const { defaultCollection } = useCredentials()
  const { createCredentialItem } = useCredential()
  const { currentTheme } = useVESSTheme()
  const { resetUploadStatus, upload } = useFileUpload()
  const [isCustomImageSelected, _] = useStateIsCustomImageSelected()
  const router = useRouter()
  const { showLoading, closeLoading } = useVESSLoading()
  const parentId = router.query.pId as string
  const { member } = useVESSMember(workspace?.orgId)

  const methods = useForm<CredItemInput>({
    defaultValues: {
      title: '',
      description: '',
      primaryColor: '#000000',
      image: '',
      tags: [],
      customImage: null,
      customImagePreview: '',
    },
    mode: 'onSubmit',
  })

  const onClickSubmit = async (data: CredItemInput) => {
    if (!workspace?.orgId || !defaultCollection || !type || !member?.id) return

    showLoading(DEFAULT_LOADING_MESSAGE)

    const {
      startDateInput,
      endDateInput,
      customImage,
      customImagePreview,
      tags,
      stickerFiles,
      stickerPreviews,
      ...items
    } = data

    // Upload credential item image
    let imageUrl
    if (isCustomImageSelected) {
      // upload custom image
      if (!customImage) {
        closeLoading()
        throw new Error('Failed to upload image')
      }
      imageUrl = await upload(customImage)
    } else {
      // generate image
      const outputWidth = CARD_WIDTH
      const outputHeight = CARD_HEIGHT
      const imageData = await GenerateCredentialImage({
        width: outputWidth,
        height: outputHeight,
        primaryColor: items.primaryColor,
        title: items.title,
        issuerName: workspace.name,
        logoImage: workspace?.icon || '',
        startDate: formatDate(startDateInput?.toISOString()) || '',
        endDate: formatDate(endDateInput?.toISOString()) || '',
      })
      if (!imageData) {
        closeLoading()
        throw new Error('Failed to generate image')
      }
      imageUrl = await upload(imageData)
    }

    //generate sticker images
    let stickers: string[] = []
    if (stickerFiles && stickerFiles.length > 0) {
      for (const sticker of stickerFiles) {
        const stickerUrl = await upload(sticker)
        if (!stickerUrl) {
          closeLoading()
          throw new Error('Failed to upload sticker')
        }
        stickers.push(stickerUrl)
      }
    }

    const credItem: CredItemRequest = {
      ...items,
      tags: tags && tags.length > 0 ? tags : [],
      stickers: stickers && stickers.length > 0 ? stickers : undefined,
      startDate: startDateInput?.toISOString() || '',
      endDate: endDateInput?.toISOString() || '',
      collectionId: parentId || defaultCollection.id,
      icon: workspace?.icon || '',
      image: imageUrl || '',
      credentialTypeName: type,
      saveCompose: workspace.useCompose || false,
      expirationDate: items.expirationInput?.toISOString() || '',
      validDuraion: items.validDuraion,
      organizationId: workspace?.orgId || '',
    }
    const isSucceed = await createCredentialItem(credItem)
    if (isSucceed) {
      resetUploadStatus()
      methods.reset()
      router.back()
    }
    closeLoading()
    return
  }

  const onCancelHandler = () => {
    resetUploadStatus()
    methods.reset()
    router.back()
  }

  return (
    <HCLayout
      backgroundColor='var(--kai-color-sys-background)'
      header={
        <BasicHeader
          title='新規クレデンシャル作成'
          variant='modal'
          CTA={
            <Button variant='text' onPress={onCancelHandler}>
              キャンセル
            </Button>
          }
        />
      }
    >
      <ArticleContentLayout maxWidth='1024px'>
        <FormProvider {...methods}>
          <FormFrame onSubmit={methods.handleSubmit(onClickSubmit)}>
            <FlexHorizontal gap='40px' padding='0px 24px' width='100%' alignItems='start'>
              <ImageBuilderFrame>
                <FlexVertical gap='8px' width={'100%'} padding={'16px 0px'}>
                  <Text
                    as='h4'
                    startContent={<FaImage />}
                    color='var(--kai-color-sys-on-layer-minor)'
                  >
                    スタイル設定
                  </Text>
                  <CredCustomImagePreview workspace={workspace} />
                </FlexVertical>
                <CredCustomImagePicker />
                <FlexVertical gap='8px' width='100%'>
                  <Text as='p'>テーマカラー</Text>
                  <CredColorPicker targetImage={workspace?.icon || ''} />
                </FlexVertical>
                <FlexVertical gap='8px' width='100%'>
                  <StickerImagePicker />
                </FlexVertical>
              </ImageBuilderFrame>
              <InputFrame
                backgroundColor={currentTheme.surfaceContainerLowest}
                outlineColor={currentTheme.outlineVariant}
              >
                <Text as='h2' color='var(--kai-color-sys-on-layer)'>
                  情報を入力してください。
                </Text>
                <TextInput
                  label='タイトル'
                  {...methods.register('title', {
                    required: 'タイトルを入力してください。',
                  })}
                  width='100%'
                  align='vertical'
                  isRequired
                  autoFocus
                  placeholder='クレデンシャルのタイトル'
                  errorMessage={methods.formState.errors.title?.message}
                />
                <TextArea
                  label='詳細(任意)'
                  {...methods.register('description', { required: false })}
                  width='100%'
                  align='vertical'
                  height='var(--kai-size-ref-160)'
                  placeholder='クレデンシャルの詳細を入力'
                ></TextArea>
                <TextInput
                  label='リンク(任意)'
                  {...methods.register('link', { required: false })}
                  width='100%'
                  align='vertical'
                  placeholder='https://example.com/hogehoge'
                  description='任意のURLをクレデンシャルに紐づけられます。'
                />
                {type === 'attendance' && (
                  <FlexHorizontal gap='16px' width='100%'>
                    <BaseDatePicker
                      label={'イベント開始日'}
                      name='startDateInput'
                      control={methods.control}
                      error={methods.formState.errors.startDateInput?.message}
                    />
                    <div
                      style={{
                        border: `0.5px solid ${currentTheme.onSurfaceVariant}`,
                        height: '0px',
                        flex: 1,
                      }}
                    />
                    <BaseDatePicker
                      label={'終了日'}
                      name='endDateInput'
                      control={methods.control}
                      error={methods.formState.errors.endDateInput?.message}
                    />
                  </FlexHorizontal>
                )}
                <ExpirationForm />
                <FlexHorizontal gap='16px' width='100%'>
                  <TagSelect
                    control={methods.control}
                    name={'tags'}
                    label={'タグ'}
                    options={[]}
                    tags={methods.getValues('tags')}
                    error={methods.formState.errors.tags?.message}
                  />
                </FlexHorizontal>
                <FlexHorizontal width='100%' justifyContent='flex-end' gap='8px'>
                  <Button variant='tonal' color='dominant' onPress={onCancelHandler} type='button'>
                    キャンセル
                  </Button>
                  <Button variant='filled' color='dominant' type='submit'>
                    作成する
                  </Button>
                </FlexHorizontal>
              </InputFrame>
            </FlexHorizontal>
          </FormFrame>
        </FormProvider>
      </ArticleContentLayout>
    </HCLayout>
  )
}

const ImageBuilderFrame = styled.div`
  width: var(--kai-size-ref-288);
  display: flex;
  flex-direction: column;
  gap: var(--kai-size-sys-space-lg);
  padding: 0;
`

const FormFrame = styled.form`
  width: 100%;
  height: auto;
`
const InputFrame = styled.div<{ backgroundColor: string; outlineColor: string }>`
  flex: 1;
  height: auto;
  background: var(--kai-color-sys-layer-farther);
  border: var(--kai-size-ref-1) solid var(--kai-color-sys-neutral-outline-minor);
  border-radius: var(--kai-size-sys-round-lg);
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: var(--kai-size-sys-space-lg);
`
