export const ChainIcon = () => (
  <svg viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.1667 18.4167C14.7751 19.23 15.5513 19.903 16.4427 20.39C17.3341 20.877 18.3197 21.1666 19.3328 21.2392C20.346 21.3117 21.3628 21.1656 22.3145 20.8106C23.2661 20.4556 24.1303 19.9 24.8484 19.1817L29.0984 14.9317C30.3887 13.5958 31.1027 11.8065 31.0865 9.94927C31.0704 8.09205 30.3254 6.31546 29.0121 5.00215C27.6988 3.68884 25.9222 2.9439 24.065 2.92776C22.2078 2.91162 20.4185 3.62558 19.0826 4.91587L16.6459 7.33837'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.8334 15.5832C19.225 14.7699 18.4488 14.0969 17.5574 13.6099C16.6661 13.1229 15.6804 12.8333 14.6673 12.7608C13.6542 12.6882 12.6373 12.8344 11.6856 13.1894C10.734 13.5444 9.86979 14.0999 9.1517 14.8182L4.90171 19.0682C3.61142 20.4042 2.89746 22.1934 2.9136 24.0507C2.92974 25.9079 3.67468 27.6845 4.98799 28.9978C6.3013 30.3111 8.07789 31.056 9.93511 31.0722C11.7923 31.0883 13.5816 30.3744 14.9175 29.0841L17.34 26.6616'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
