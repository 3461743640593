import React from 'react'

export const TwitterIcon: React.FC = () => (
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.1335 10.7258C27.1505 10.9753 27.1505 11.2249 27.1505 11.4767C27.1505 19.1502 21.3719 28 10.8055 28V27.9954C7.68418 28 4.62767 27.0962 2 25.392C2.45387 25.4472 2.91001 25.4748 3.3673 25.4759C5.95401 25.4782 8.46679 24.6008 10.5018 22.9852C8.04363 22.938 5.88804 21.3178 5.135 18.9524C5.9961 19.1203 6.88336 19.0858 7.72854 18.8523C5.04855 18.305 3.12046 15.9246 3.12046 13.1602C3.12046 13.1349 3.12046 13.1107 3.12046 13.0866C3.91899 13.5362 4.81308 13.7857 5.72765 13.8133C3.20349 12.108 2.42543 8.71342 3.94971 6.05939C6.8663 9.68741 11.1695 11.893 15.789 12.1264C15.326 10.1094 15.9585 7.99586 17.4509 6.578C19.7646 4.37934 23.4035 4.49203 25.5785 6.82984C26.865 6.5734 28.0981 6.09618 29.2265 5.42003C28.7976 6.76429 27.9001 7.90617 26.7012 8.63177C27.8399 8.49608 28.9523 8.1879 30 7.71758C29.2288 8.88591 28.2573 9.90359 27.1335 10.7258Z'
      fill='currentColor'
    />
  </svg>
)
