import styled from '@emotion/styled'
import { useModalContext } from 'kai-kit'
import React, { FC } from 'react'

type Props = {
  children?: React.ReactNode
  showGrid?: boolean
  backgroundColor?: string
}

export const FullContentLayout: FC<Props> = ({ children, showGrid = false, backgroundColor }) => {
  const { attachToRef, isOpenSomeModal } = useModalContext()

  return (
    <LayoutContainer
      ref={attachToRef}
      data-modal-open={isOpenSomeModal}
      backgroundColor={backgroundColor ? backgroundColor : 'var(--kai-color-sys-background)'}
    >
      {children}
    </LayoutContainer>
  )
}
const LayoutContainer = styled.div<{ backgroundColor: string }>`
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.backgroundColor};
  transition: all var(--kai-motion-sys-duration-fast) var(--kai-motion-sys-easing-standard);
  transition-property: transform opacity;
  &[data-modal-open='true'] {
    transform: scale(0.98);
    opacity: 0.4;
  }
`
