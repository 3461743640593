export const Person = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5 10.35C12.415 10.35 13.2925 9.98647 13.9395 9.33947C14.5865 8.69247 14.95 7.81495 14.95 6.89995C14.95 5.98495 14.5865 5.10743 13.9395 4.46043C13.2925 3.81343 12.415 3.44995 11.5 3.44995C10.585 3.44995 9.70748 3.81343 9.06048 4.46043C8.41348 5.10743 8.05 5.98495 8.05 6.89995C8.05 7.81495 8.41348 8.69247 9.06048 9.33947C9.70748 9.98647 10.585 10.35 11.5 10.35V10.35ZM3.45 20.7C3.45 19.6428 3.65822 18.596 4.06277 17.6193C4.46732 16.6427 5.06028 15.7553 5.80779 15.0077C6.5553 14.2602 7.44272 13.6673 8.4194 13.2627C9.39607 12.8582 10.4429 12.65 11.5 12.65C12.5571 12.65 13.6039 12.8582 14.5806 13.2627C15.5573 13.6673 16.4447 14.2602 17.1922 15.0077C17.9397 15.7553 18.5327 16.6427 18.9372 17.6193C19.3418 18.596 19.55 19.6428 19.55 20.7H3.45Z'
      fill='currentColor'
    />
  </svg>
)
