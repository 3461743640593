import styled from '@emotion/styled'
import { Button, Menu, MenuItem, useModal, Separator, Skelton, Text } from 'kai-kit'
import { useRouter } from 'next/router'
import { FC, useEffect, useMemo } from 'react'
import { Button as RACButton } from 'react-aria-components'
import { FiHome, FiAward } from 'react-icons/fi'
import {
  PiPlusCircleBold,
  PiFolderSimplePlusDuotone,
  PiTicketDuotone,
  PiIdentificationCardDuotone,
  PiGraduationCapDuotone,
  PiDotsThreeVerticalBold,
  PiCertificateDuotone,
  PiSignOutFill,
  PiHouseLineDuotone,
  PiGearDuotone,
} from 'react-icons/pi'
import { AddCollectionModal } from '../Modal/Credential/AddCollectionModal'
import { FlexVertical } from '@/components/atom/Commons/FlexVertical'
import { ImageContainer } from '@/components/atom/Images/ImageContainer'
import { NavigationItem } from '@/components/molecule/Navigation/NavigationItem'
import { CredTypeUnion } from '@/components/templates/credential/AddCredItemContainer'
import { getNaviItem, NAVIGATION_LIST_TYPE, NAVIGATION_LIST_V2 } from '@/constants/ui'
import { useCredentials } from '@/hooks/useCredentials'
import { useDID } from '@/hooks/useDID'
import { useVESSMember } from '@/hooks/useVESSMember'
import { useVESSTheme } from '@/hooks/useVESSTheme'
import { useWorkspace } from '@/hooks/useWorkspace'
import { useStateSelectedNavigationItem } from '@/jotai/ui'

const NaviIcons: FC<{ icon: string; color?: string; size?: string }> = ({ icon, color, size }) => {
  switch (icon) {
    case 'home':
      return <PiHouseLineDuotone size={size} color={color} />
    case 'membership':
      return <PiIdentificationCardDuotone size={size} color={color} />
    case 'jobs':
      return <FiHome size={size} color={color} />
    case 'event':
      return <FiAward size={size} color={color} />
    case 'setting':
      return <PiGearDuotone size={size} color={color} />
    case 'certificate':
      return <PiGraduationCapDuotone size={size} color={color} />
    case 'credential':
      return <PiCertificateDuotone size={size} color={color} />
    case 'attendance':
      return <PiTicketDuotone size={size} color={color} />
    default:
      return <FiHome size={size} color={color} />
  }
}

export const NavigationList: FC = () => {
  const [item, setItem] = useStateSelectedNavigationItem()
  const router = useRouter()
  const { workspace, isLoadingOrg } = useWorkspace()
  const { isEditor } = useVESSMember(workspace?.orgId)
  const { openModal, closeModal } = useModal()
  const { auth0Logout } = useDID()
  const { getText } = useVESSTheme()

  const collectionId = useMemo(() => {
    return router.query.collectionId ? (router.query.collectionId as string) : ''
  }, [router])

  const { collection } = useCredentials(collectionId)
  useEffect(() => {
    const naviItem = getNaviItem(router.asPath)
    if (naviItem.item === item) return
    setItem(naviItem.item)
  }, [router])

  const handleClick = (navi: NAVIGATION_LIST_TYPE) => {
    setItem(navi.item)
    router.push(navi.path)
  }
  return (
    <>
      <BasicHeaderContainer>
        <Skelton width='200px' height='32px' isLoading={isLoadingOrg}>
          <Menu
            density='comfortable'
            placement='bottom'
            width='var(--kai-size-ref-256)'
            onAction={(key) => {
              switch (key) {
                case 'logout':
                  auth0Logout()
                  break
                default:
                  break
              }
            }}
            triggerButton={
              workspace && (
                <OrgButton>
                  <Flex>
                    <OrgIconFrame>
                      {workspace.icon && <ImageContainer src={workspace.icon} width='32px' />}
                    </OrgIconFrame>
                    <Text as='h3' typo='title-lg' color='var(--kai-color-sys-on-layer)'>
                      {workspace.name}
                    </Text>
                  </Flex>
                  <PiDotsThreeVerticalBold color='inherit' size='24px' />
                </OrgButton>
              )
            }
          >
            <MenuItem id='logout' startConent={<PiSignOutFill />}>
              ログアウト
            </MenuItem>
          </Menu>
        </Skelton>
      </BasicHeaderContainer>
      <NavigationListContainer>
        {isEditor && (
          <Menu
            triggerButton={
              <Button startContent={<PiPlusCircleBold />} size='lg' width='100%' align='start'>
                新しく作成
              </Button>
            }
            variant='filled'
            density='comfortable'
            width={'var(--kai-size-ref-256)'}
            onAction={(key) => {
              switch (key) {
                case 'attendance':
                  router.push(`/credential/add/attendance?pId=${collectionId}`)
                  break
                case 'membership':
                  router.push(`/credential/add/membership?pId=${collectionId}`)
                  break
                case 'certificate':
                  router.push(`/credential/add/certificate?pId=${collectionId}`)
                  break
                case 'collection':
                  openModal('addCollectionModal')
                  break
                default:
                  break
              }
            }}
          >
            {CredTypeUnion.slice(0, -1).map((type) => {
              return (
                <>
                  <MenuItem key={type} id={type} startConent={<NaviIcons icon={type.toString()} />}>
                    {getText('Common', type)}
                  </MenuItem>
                </>
              )
            })}
            {/* <Separator /> */}
            <MenuItem id='collection' startConent={<PiFolderSimplePlusDuotone />}>
              コレクションを作成
            </MenuItem>
          </Menu>
        )}

        <FlexVertical width='100%' gap='var(--kai-size-sys-space-none)'>
          {NAVIGATION_LIST_V2.map((navi) => {
            return (
              <NavigationItem
                key={navi.item}
                title={navi.item}
                icon={<NaviIcons icon={navi.icon} size={'23px'} />}
                onClick={() => handleClick(navi)}
                selected={item === navi.item}
              />
            )
          })}
        </FlexVertical>
      </NavigationListContainer>
      <AddCollectionModal
        modalName='addCollectionModal'
        parentId={collectionId}
        credentialType={collection?.credentialType}
      />
    </>
  )
}

const Flex = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  column-gap: var(--kai-size-sys-space-sm);
  overflow: hidden;
  margin-right: var(--kai-size-sys-space-sm);
`

const NavigationListContainer = styled.div`
  background: var(--kai-color-sys-layer-farther);
  padding: var(--kai-size-ref-24) var(--kai-size-ref-12);
  display: flex;
  flex-direction: column;
  gap: var(--kai-size-sys-space-lg);
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
  width: 280px;
`
const BasicHeaderContainer = styled.div`
  background: var(--kai-color-sys-layer-farther);
  align-self: stretch;
  padding: var(--kai-size-sys-space-sm);
  height: var(--kai-size-ref-80);
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--kai-size-sys-space-sm);
  border-bottom: 1px solid var(--kai-color-sys-neutral-outline-minor);
`
const OrgButton = styled(RACButton)`
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--kai-size-sys-space-md);
  border: none;
  border-radius: var(--kai-size-sys-round-md);
  transition: all var(--kai-motion-sys-duration-fast) var(--kai-motion-sys-easing-standard);
  transition-property: background-color, opacity, transform;
  color: var(--kai-color-sys-on-layer-minor);

  &[data-hovered] {
    background: var(--kai-color-sys-layer-default);
    cursor: pointer;
    transform: scale(1.02);
  }
  &[data-pressed] {
    background: var(--kai-color-sys-layer-default);
    transform: scale(0.98);
    opacity: 0.8;
  }

  &[data-focused] {
    outline: none;
  }
  &[data-focus-visible] {
    outline: var(--kai-size-ref-2) solid var(--kai-color-sys-dominant);
    outline-offset: var(--kai-size-ref-2);
  }
  &[data-disabled] {
    opacity: 0.4;
    cursor: default;
  }
`

const OrgIconFrame = styled.div`
  width: var(--kai-size-ref-32);
  height: fit-content;
  flex-shrink: 0;
`
