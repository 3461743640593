export const MailIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.00244 6.3985L9.99982 9.89675L16.9972 6.3985C16.9713 5.95264 16.7759 5.53355 16.451 5.22707C16.1262 4.92059 15.6964 4.74992 15.2498 4.75H4.74982C4.3032 4.74992 3.87346 4.92059 3.5486 5.22707C3.22374 5.53355 3.02834 5.95264 3.00244 6.3985Z'
      fill='currentColor'
    />
    <path
      d='M17 8.35303L10 11.853L3 8.35303V13.4998C3 13.9639 3.18437 14.409 3.51256 14.7372C3.84075 15.0654 4.28587 15.2498 4.75 15.2498H15.25C15.7141 15.2498 16.1592 15.0654 16.4874 14.7372C16.8156 14.409 17 13.9639 17 13.4998V8.35303Z'
      fill='currentColor'
    />
  </svg>
)
