export const GithubIcon = () => (
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5184 1.05465C12.0718 1.05107 8.73662 2.30333 6.1105 4.58704C3.48438 6.87075 1.73899 10.0366 1.18714 13.5173C0.635301 16.998 1.31307 20.566 3.09897 23.5819C4.88487 26.5978 7.66218 28.8644 10.9332 29.9756C11.6544 30.1122 11.9215 29.6477 11.9215 29.256C11.9215 28.8643 11.9215 27.9716 11.9215 26.7328C7.88821 27.6437 7.03348 24.7471 7.03348 24.7471C6.76473 23.8493 6.19379 23.078 5.42197 22.57C4.11317 21.6591 5.52881 21.6591 5.52881 21.6591C5.9876 21.7254 6.42562 21.8977 6.80972 22.1628C7.19381 22.4279 7.51391 22.779 7.74576 23.1894C7.94276 23.5549 8.20834 23.8769 8.52729 24.1371C8.84624 24.3973 9.21228 24.5905 9.60438 24.7056C9.99648 24.8207 10.4069 24.8554 10.8122 24.8078C11.2175 24.7603 11.6095 24.6313 11.966 24.4283C12.0218 23.6789 12.3376 22.9747 12.8563 22.4425C9.63327 22.069 6.24998 20.7938 6.24998 15.1098C6.22785 13.6368 6.76021 12.2116 7.73685 11.1292C7.301 9.8486 7.35185 8.44718 7.8793 7.2032C7.8793 7.2032 9.09907 6.80239 11.868 8.72438C14.2458 8.05648 16.7555 8.05648 19.1332 8.72438C21.9022 6.80239 23.113 7.2032 23.113 7.2032C23.646 8.44541 23.7001 9.84787 23.2644 11.1292C24.241 12.2116 24.7734 13.6368 24.7513 15.1098C24.7513 20.812 21.3591 22.0599 18.1271 22.3969C18.4737 22.7534 18.7413 23.1818 18.9118 23.6533C19.0823 24.1248 19.1517 24.6283 19.1154 25.1296C19.1154 27.1154 19.1154 28.7186 19.1154 29.2013C19.1154 29.6841 19.3736 30.0576 20.1126 29.921C23.3733 28.7993 26.1385 26.529 27.915 23.5151C29.6916 20.5012 30.3639 16.9397 29.812 13.4658C29.2602 9.99185 27.5201 6.83144 24.9021 4.54824C22.2841 2.26503 18.9585 1.00752 15.5184 1V1.05465Z'
      fill='currentColor'
    />
  </svg>
)
