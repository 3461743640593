import { FinalColor } from 'extract-colors/lib/types/Color'
import { atom, useAtom } from 'jotai'

export const uploadStatus = atom<'completed' | 'uploading' | 'failed' | undefined>(undefined)

export const useStateUploadStatus = () => useAtom(uploadStatus)

export const uploadedIconUrl = atom<string | undefined>(undefined)

export const useStateUploadedIconUrl = () => useAtom(uploadedIconUrl)

export const uploadedIconName = atom<string | undefined>(undefined)

export const useStateUploadedIconName = () => useAtom(uploadedIconName)

export const uploadedCoverImageUrl = atom<string | undefined>(undefined)

export const useStateUploadedCoverImageUrl = () => useAtom(uploadedCoverImageUrl)

export const uploadedCoverName = atom<string | undefined>(undefined)

export const useStateUploadedCoverName = () => useAtom(uploadedCoverName)

export const uploadedImageColors = atom<FinalColor[] | undefined>(undefined)

export const useStateUploadedImageColors = () => useAtom(uploadedImageColors)

export const uploadedImageUrl = atom<string | undefined>(undefined)

export const useStateUploadedImageUrl = () => useAtom(uploadedImageUrl)

export const uploadedImageName = atom<string | undefined>(undefined)

export const useStateUploadedImageName = () => useAtom(uploadedImageName)

export const uploadedCsv = atom<any[]>([])

export const useStateUploadedCsv = () => useAtom(uploadedCsv)

export const csvError = atom<string>('')

export const useStateCsvError = () => useAtom(csvError)
