import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useStateThemeMode, useStateTypeMode } from '@/jotai/ui'

export const useThemeMode = () => {
  const [mode, setMode] = useStateThemeMode()
  const [typo, setTypo] = useStateTypeMode()
  const router = useRouter()

  const setLightMode = useCallback(() => {
    localStorage.removeItem('theme')
    localStorage.theme = 'light'
    setMode('light')
    const root = window.document.documentElement
    root.setAttribute('data-theme', 'light')
  }, [])

  const setDarkMode = useCallback(() => {
    localStorage.removeItem('theme')
    localStorage.theme = 'dark'
    setMode('dark')
    const root = window.document.documentElement
    root.setAttribute('data-theme', 'dark')
  }, [])

  const setTypoMode = useCallback((newTypo: 'en' | 'ja') => {
    if (!localStorage) return
    localStorage.removeItem('vessTypo')
    localStorage.vessTypo = newTypo
    setTypo(newTypo)
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: newTypo })
  }, [])
  const initThemeMode = useCallback(() => {
    if (!localStorage) return
    // for typo
    const browserLang =
      (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language
    if (localStorage.vessTypo === 'ja' || browserLang === 'ja') {
      setTypoMode('ja')
    } else {
      setTypoMode('en')
    }

    // for theme
    // light mode only for now.
    setLightMode()
    // if (
    //   localStorage.theme === 'light' ||
    //   (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: light)').matches)
    // ) {
    //   setLightMode()
    // } else {
    //   setDarkMode()
    // }
  }, [])

  return { setLightMode, setDarkMode, initThemeMode, themeMode: mode, typoMode: typo, setTypoMode }
}
