export const removeUndefined = <T extends {}>(object: T): T => {
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => v !== undefined)) as T
}

export const removeUndefinedFromArray = <T>(arr: Array<T | undefined>): Array<T> => {
  return arr.filter((a) => a !== undefined) as Array<T>
}

export const shortenStr = (str?: string, length = 20): string => {
  if (!str) return ''
  const half = Math.floor(length / 2)
  const remaining = half - 3 - length
  return str.length <= length ? str : `${str.slice(0, half)}...${str.slice(remaining)}`
}

export const shortenStrWithEndDots = (str?: string, length = 20): string => {
  if (!str) return ''
  return str.length <= length ? str : `${str.slice(0, length)}...`
}

export const shortHash = (hash?: string, maxLength: number = 20) => {
  if (!hash) return ''
  const half = Math.floor(maxLength / 2)
  const remaining = half - maxLength
  return hash.length <= maxLength ? hash : `${hash.slice(0, half)}...${hash.slice(remaining)}`
}

export const renameType = (obj: { id?: string | undefined } & { [x: string]: any }) => {
  const keyValues = Object.keys(obj).map((key) => {
    if (key === 'type') {
      return { ['_type']: obj[key] }
    } else {
      return { [key]: obj[key] }
    }
  })
  return Object.assign({}, ...keyValues)
}

// color
type RGB = { red: number; green: number; blue: number }

export const toHexColor = (color: RGB) => {
  const { red, green, blue } = color
  return '#' + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue)
}

const ColorToHex = (color: number) => {
  const hexadecimal = color.toString(16)
  return hexadecimal.length == 1 ? '0' + hexadecimal : hexadecimal
}

export const hue2rgb = (h: number, s: number, l: number): RGB => {
  const RGB_MAX = 255
  const HUE_MAX = 360
  const SATURATION_MAX = 100
  const LIGHTNESS_MAX = 100
  let r, g, b, max, min

  h = h % HUE_MAX
  s = s / SATURATION_MAX
  l = l / LIGHTNESS_MAX

  if (l < 0.5) {
    max = l + l * s
    min = l - l * s
  } else {
    max = l + (1 - l) * s
    min = l - (1 - l) * s
  }

  const hp = HUE_MAX / 6
  const q = h / hp
  if (q <= 1) {
    r = max
    g = (h / hp) * (max - min) + min
    b = min
  } else if (q <= 2) {
    r = ((hp * 2 - h) / hp) * (max - min) + min
    g = max
    b = min
  } else if (q <= 3) {
    r = min
    g = max
    b = ((h - hp * 2) / hp) * (max - min) + min
  } else if (q <= 4) {
    r = min
    g = ((hp * 4 - h) / hp) * (max - min) + min
    b = max
  } else if (q <= 5) {
    r = ((h - hp * 4) / hp) * (max - min) + min
    g = min
    b = max
  } else {
    r = max
    g = min
    b = ((HUE_MAX - h) / hp) * (max - min) + min
  }

  return {
    red: Math.round(r * RGB_MAX),
    green: Math.round(g * RGB_MAX),
    blue: Math.round(b * RGB_MAX),
  }
}

export function rgb2Hsl(color: string): { hue: number; saturation: number; lightness: number } {
  // カラーコードをRGBに変換
  const r = parseInt(color.slice(1, 3), 16) / 255
  const g = parseInt(color.slice(3, 5), 16) / 255
  const b = parseInt(color.slice(5, 7), 16) / 255

  // RGBからHSLに変換
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const diff = max - min
  const sum = max + min
  const l = sum / 2
  let h = 0
  let s = 0
  if (diff !== 0) {
    s = l > 0.5 ? diff / (2 - sum) : diff / sum
    switch (max) {
      case r:
        h = (g - b) / diff + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / diff + 2
        break
      case b:
        h = (r - g) / diff + 4
        break
    }
    h /= 6
  }

  return {
    hue: h,
    saturation: s,
    lightness: l,
  }
}

export const getImageData = async (url: string) => {
  return fetch(url)
    .then((response) => {
      return response.blob()
    })
    .then((blob) => {
      return URL.createObjectURL(blob)
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}

export const convertDateStringToDate = (potentialDateStr: any): Date => {
  if (typeof potentialDateStr === 'string') {
    return new Date(potentialDateStr)
  }
  return potentialDateStr as Date
}

export const cvtDataURL2File = async (dataURL: string, filename: string): Promise<File> => {
  const blob = await (await fetch(dataURL)).blob()
  return new File([blob], filename, { type: blob.type })
}

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (e) => reject(e)
    img.src = src
  })
}
