import { FlexHorizontal, FlexVertical, Text, TextInput } from 'kai-kit'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseDatePicker } from '@/components/atom/Forms/BaseDatePicker'

export const ExpirationForm: FC = () => {
  const { register, formState, control } = useFormContext()

  return (
    <FlexVertical gap='4px'>
      <Text as='p' typo='label-lg' color={'var(--kai-color-sys-on-layer-minor)'}>
        有効期限設定(日数または日付を選択してください)(※両方値がある場合には日付が優先されます)
      </Text>
      <FlexHorizontal gap='16px' width='100%'>
        <TextInput
          type='number'
          label=''
          isLabel={false}
          {...register('validDuraion', { valueAsNumber: true })}
          align='vertical'
          autoFocus
          placeholder='期間(日)'
          errorMessage={(formState.errors.validDuraion?.message as string) || ''}
        />
        <Text as='span' typo='label-lg' color={'var(--kai-color-sys-on-layer-minor)'}>
          OR
        </Text>
        <BaseDatePicker
          name='expirationInput'
          control={control}
          error={(formState.errors.expirationInput?.message as string) || ''}
        />
      </FlexHorizontal>
    </FlexVertical>
  )
}
