export const EditIcon = () => (
  <svg viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M24.0833 4.25002C24.4553 3.87794 24.8971 3.58279 25.3832 3.38143C25.8693 3.18006 26.3904 3.07642 26.9166 3.07642C27.4428 3.07642 27.9638 3.18006 28.45 3.38143C28.9361 3.58279 29.3778 3.87794 29.7499 4.25002C30.122 4.6221 30.4171 5.06382 30.6185 5.54997C30.8199 6.03611 30.9235 6.55716 30.9235 7.08335C30.9235 7.60955 30.8199 8.1306 30.6185 8.61674C30.4171 9.10289 30.122 9.54461 29.7499 9.91669L10.6249 29.0417L2.83325 31.1667L4.95825 23.375L24.0833 4.25002Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
