import { addCeramicPrefix } from 'vess-kit-web'
import { CredentialType } from '@/@types/credential'
import {
  CreateKmsOrganizationType,
  IssueCollectionRequest,
  KmsOrganizationType,
  MigrateKmsOrganizationType,
} from '@/@types/kms'
import { VESS_ENDPOINT_TYPE } from '@/server/kms'
import { getEnv } from '@/utils/envUtil'
import { isGoodResponse } from '@/utils/http'
import { getCurrentDomain } from '@/utils/url'

export type User = {
  id: string
  name: string | null
  avatar: string | null
  description: string | null
  did: string | null
  email: string | null
  createdAt: Date
  updatedAt: Date
}

export type GetCollectionResponse = {
  id: string
  organizationId: string
  ceramicId: string
  name: string
  parentId?: string
  parent?: GetCollectionResponse
  credentialType: string
  children?: GetCollectionResponse[]
  createdAt: string
  updatedAt: string
}

export type VSCredential = {
  id: string
  organizationId: string
  credentialTypeId: string | null
  issuerDid: string
  holderDid: string
  ceramicId: string | null
  plainCredential: string
  createdAt: Date
  updatedAt: Date
  credentialItemId: string
}

type Tag = {
  id: string
  name: string
}

type Tagged = {
  tag: Tag
}
export type VSCredentialItemFromBuckup = {
  id: string
  ceramicId: string | undefined
  credentialTypeId: string
  credentialType: CredentialType
  collectionId: string
  organizationId: string | null
  title: string
  description: string | null
  icon: string | null
  image: string | null
  Tagged: Tagged[]
  primaryColor: string | null
  startDate: string | null
  endDate: string | null
  createdAt: string
  updatedAt: string
  expirationDate?: string
  validDuraion?: number
  credentials?: VSCredential[]
  sticker?: Sticker[]
}

export type Sticker = {
  id: string
  credentialItemId: string
  image: string
}

export interface CredentialWithHolder extends VSCredential {
  holder: User | null
}

export type VSCredentialItemFrombackupWithUser = VSCredentialItemFromBuckup & {
  credentialsWithHolder?: CredentialWithHolder[]
}

export type GetCollectionItemResponse = GetCollectionResponse & {
  items: VSCredentialItemFromBuckup[]
}

export const updateOrganization = async (
  orgId: string,
  memberId: string,
  body: any,
): Promise<Response> => {
  try {
    return await baseVessApi('PUT', '/organizations', undefined, orgId, memberId, body)
  } catch (error) {
    throw error
  }
}

export const getAllCollections = async (
  organizationId?: string,
  memberId?: string,
): Promise<GetCollectionResponse[]> => {
  if (!organizationId) {
    throw new Error('organizationId is undefined')
  }
  try {
    const res = await baseVessApi('GET', '/v2/collection/all', undefined, organizationId, memberId)
    const resjson = await res.json()
    console.log({ resjson })
    return resjson.collections as GetCollectionResponse[]
  } catch (error) {
    throw error
  }
}

export const getCollection = async (
  orgId: string,
  memberId: string,
  collectionId?: string,
): Promise<GetCollectionItemResponse> => {
  if (!collectionId) {
    throw new Error('collectionId is undefined')
  }
  try {
    const res = await baseVessApi(
      'GET',
      '/v2/collection',
      undefined,
      orgId,
      memberId,
      undefined,
      collectionId,
    )
    const resjson = await res.json()
    return resjson as GetCollectionItemResponse
  } catch (error) {
    throw error
  }
}

export const getDefaultCollections = async (
  organizationId?: string,
  memberId?: string,
): Promise<GetCollectionItemResponse> => {
  if (!organizationId) {
    throw new Error('organizationId is undefined')
  }
  try {
    const res = await baseVessApi(
      'GET',
      '/v2/collection/default',
      undefined,
      organizationId,
      memberId,
      undefined,
      organizationId,
    )
    const resjson = await res.json()
    return resjson as GetCollectionItemResponse
  } catch (error) {
    throw error
  }
}

export const createCollection = async (
  orgId: string,
  memberId: string,
  body: IssueCollectionRequest,
): Promise<Response> => {
  try {
    console.log({ orgId })
    return await baseVessApi('POST', '/v2/collection', undefined, orgId, memberId, body)
  } catch (error) {
    throw error
  }
}

export const getCredentialItem = async (
  id?: string,
  showHolders: boolean = false,
  orgId?: string,
  memberId?: string,
): Promise<VSCredentialItemFromBuckup> => {
  if (!id) {
    throw new Error('id is undefined')
  }
  try {
    const res = await baseVessApi(
      'GET',
      '/v2/collection/items',
      undefined,
      orgId,
      memberId,
      undefined,
      id,
      showHolders ? 'showHolders=true' : undefined,
    )
    const resjson = await res.json()
    return resjson as VSCredentialItemFromBuckup
  } catch (error) {
    throw error
  }
}

export const createCredentialItem = async (
  orgId: string,
  memberId: string,
  body: any,
): Promise<Response> => {
  try {
    return await baseVessApi('POST', '/v2/creditems/org/items', undefined, orgId, memberId, body)
  } catch (error) {
    throw error
  }
}

export const deleteCredItems = async (
  orgId: string,
  memberId: string,
  credItemId: string,
): Promise<Response> => {
  try {
    return await baseVessApi(
      'POST',
      '/v2/creditems/org/items/delete',
      undefined,
      orgId,
      memberId,
      undefined,
      credItemId,
    )
  } catch (error) {
    throw error
  }
}

export const getCredentialsByIssuer = async (
  did?: string,
  orgId?: string,
  memberId?: string,
): Promise<Response> => {
  if (!did) {
    throw new Error('did is undefined')
  }
  try {
    return await baseVessApi(
      'GET',
      '/v2/credential/issuer',
      undefined,
      orgId,
      memberId,
      undefined,
      did,
    )
  } catch (error) {
    throw error
  }
}

export const issueVerifiableCredentials = async (
  body: any,
  orgId?: string,
  memberId?: string,
): Promise<Response> => {
  try {
    return await baseVessApi('POST', '/v2/credential/issue', undefined, orgId, memberId, body)
  } catch (error) {
    throw error
  }
}

export const updateVerifiableCredentials = async (
  body: any,
  orgId?: string,
  memberId?: string,
): Promise<Response> => {
  try {
    return await baseVessApi('POST', '/v2/credential/update', undefined, orgId, memberId, body)
  } catch (error) {
    throw error
  }
}

export const getKmsOrganizations = async (): Promise<KmsOrganizationType[]> => {
  try {
    const res = await baseVessApi('GET', '/organizations')
    if (isGoodResponse(res.status)) {
      const json = await res.json()
      return json as KmsOrganizationType[]
    } else {
      console.log('error', res)
    }
    return []
  } catch (error) {
    console.error(error)
    return []
  }
}

export const migrateKmsOrganizations = async (
  body: MigrateKmsOrganizationType,
): Promise<Response> => {
  try {
    const res = await baseVessApi(
      'POST',
      '/organizations/migrate',
      undefined,
      body.orgId,
      body.memberId,
      body,
    )
    return res
  } catch (error) {
    throw error
  }
}

export const createKmsOrganizations = async (
  body: CreateKmsOrganizationType,
): Promise<Response> => {
  try {
    const res = await baseVessApi('POST', '/organizations', undefined, undefined, undefined, body)
    return res
  } catch (error) {
    throw error
  }
}
export const getKmsSession = async (orgId: string, memberId?: string): Promise<Response> => {
  try {
    return await baseVessApi('POST', '/session', undefined, orgId, memberId)
  } catch (error) {
    throw error
  }
}
// export const getKmsSign = async (orgId: string, message: string): Promise<Response> => {
//   try {
//     return await baseVessApi('POST', '/sign', undefined, orgId, { message: message })
//   } catch (error) {
//     throw error
//   }
// }

export const addMembers = async (
  orgId: string,
  memberId: string,
  email: string,
  role?: string,
): Promise<Response> => {
  try {
    return await baseVessApi('POST', '/members', undefined, orgId, memberId, {
      email: email,
      role: role,
    })
  } catch (error) {
    throw error
  }
}

export const getMembers = async (orgId: string, memberId: string): Promise<Response> => {
  try {
    return await baseVessApi('GET', '/members/all', undefined, orgId, memberId)
  } catch (error) {
    throw error
  }
}

export const getMember = async (orgId: string): Promise<Response> => {
  try {
    return await baseVessApi('GET', '/members', undefined, orgId)
  } catch (error) {
    throw error
  }
}

export const setRole = async (
  orgId: string,
  memberId: string,
  targetMemberId: string,
  role: string,
): Promise<Response> => {
  try {
    return await baseVessApi(
      'PUT',
      '/members/role',
      undefined,
      orgId,
      memberId,
      { role: role },
      targetMemberId,
    )
  } catch (error) {
    throw error
  }
}

export const deleteMember = async (
  orgId: string,
  memberId: string,
  targetMemberId: string,
): Promise<Response> => {
  try {
    return await baseVessApi(
      'POST',
      '/members/delete',
      undefined,
      orgId,
      memberId,
      undefined,
      targetMemberId,
    )
  } catch (error) {
    throw error
  }
}

const baseVessApi = async (
  method: 'GET' | 'POST' | 'PUT' = 'POST',
  endpoint: VESS_ENDPOINT_TYPE,
  ceramicOrgId?: string,
  orgId?: string,
  memberId?: string,
  body?: any,
  slug?: string,
  query?: string,
): Promise<Response> => {
  try {
    const ceramicId = encodeURIComponent(ceramicOrgId ? addCeramicPrefix(ceramicOrgId) : '')
    const vessOrgId = encodeURIComponent(orgId || '')
    const vessMemberId = encodeURIComponent(memberId || '')
    let url = `${
      getCurrentDomain() || getEnv('AUTH0_BASE_URL')
    }/api/vessApi?endpoint=${endpoint}&ceramicOrgId=${ceramicId}&vessOrgId=${vessOrgId}&vessMemberId=${vessMemberId}&slug=${
      slug || ''
    }`
    if (query) {
      url = url + `&q=${encodeURIComponent(query)}`
    }
    if (method === 'GET') {
      return await fetch(url)
    } else if (method === 'PUT') {
      return await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body }),
      })
    } else {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body }),
      })
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
